import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Typography } from '@zydalabs/zac-react';
import { HelpIcon } from '@zydalabs/zac-icons-react';

import { ORDER_STATUS } from 'constants/order';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { useScrollBottom, useSelectedStore } from 'hooks';
import { context as newOrdersContext } from 'context/new-orders';
import { context as notificationsContext } from 'context/notifications';
import { Spinner } from 'components/kit';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import getOrderStatusTranslation from 'utils/getOrderStatusTranslation';
import * as schemas from '../schemas';
import ZeroResults from '../ZeroResults';
import OrderSelectionRow from './OrderSelectionRow';
import OrderItem from './OrderItem';
import BulkChangeConfirmationModal from './BulkChangeConfirmationModal';
import NoOrders from '../NoOrders';
import DELIVERY_TYPE_ENUM from '../../OrderInvoice/A4OrderInvoice/utils';

const OrderItemView = ({
  query,
  orders: fetchedOrders,
  ordersVariables,
  status,
  statuses,
  paymentStatus,
  paymentStatuses,
  showOrderStatus,
}) => {
  const [paginateFrom, setPaginateFrom] = useState(85);
  const [openedId, setOpenedId] = useState();
  const [bulkChangeStatus, setBulkChangeStatus] = useState();
  const [selecteAllOrders, setSelectAllOrders] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const [showConfirmationModal, setShowConfirmatioModal] = useState(false);
  const notifications = useContext(notificationsContext);
  const storeId = useSelectedStore();
  const { lang, translate, direction } = useContext(localeContext);
  const { user } = useContext(userContext);
  const newOrders = useContext(newOrdersContext);
  const [orders, setOrders] = useState(null);
  const selectedOrders = allOrders.filter(order => order.isSelected);
  const shouldDisableBulkChange = selectedOrders.find(
    order => order.status === ORDER_STATUS.DELIVERED || order.status === ORDER_STATUS.CANCELED,
  );
  const isPickupOrderSelected = selectedOrders.some(order => order.deliveryType === DELIVERY_TYPE_ENUM.PICKUP);
  const selectedBranchId = query?.branch !== 'all' && query.branch;

  const totalOrderNumbersPerStatus = orders?.data?.orders?.statusCount[status] || orders?.data?.orders?.totalCount;
  useEffect(() => {
    if (fetchedOrders.error && !fetchedOrders.data) {
      setOrders({ data: fetchedOrders.previousData });
    } else {
      setOrders(fetchedOrders);
    }
  }, [fetchedOrders]);

  const isBulkChange = status && status === ORDER_STATUS.PAST;
  const numberOfSelectedOrders = allOrders.filter(order => order.isSelected).length;
  const isSelectedOrders = !!numberOfSelectedOrders;

  const [updateBulkOrdersStatus, { loading: isUpdatingStatus }] = useMutation(schemas.UPDATE_BULK_ORDERS_STATUS, {
    onCompleted: () => newOrders.checkIsBulkChange(false),
  });

  useEffect(() => {
    if (ordersVariables.number) setOpenedId(orders?.data?.orders?.orders[0]?.id);
  }, [orders?.data, ordersVariables.number]);

  const orderItemWidth =
    (!!status || !!statuses || !!paymentStatus || !!paymentStatuses) && !showOrderStatus ? 'md:w-1/4' : 'md:w-1/5';
  useEffect(
    () => () => {
      newOrders.clearOrders();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useScrollBottom(() => {
    if (orders?.loading || orders?.data?.orders?.orders?.length === orders?.data?.orders?.totalCount) return;
    if (totalOrderNumbersPerStatus <= paginateFrom) return;
    const nextPagination = paginateFrom + 15;
    setPaginateFrom(nextPagination);
    orders?.fetchMore({
      variables: { ...ordersVariables, paginateFrom: nextPagination },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        if (
          fetchMoreResult &&
          prev?.orders?.orders[prev?.orders?.orders?.length - 1]?.id ===
            fetchMoreResult?.orders?.orders[fetchMoreResult?.orders?.orders?.length - 1]?.id
        ) {
          return prev;
        }
        return {
          ...prev,
          orders: {
            ...prev.orders,
            orders: [...prev?.orders?.orders, ...fetchMoreResult?.orders?.orders],
          },
        };
      },
    });
  });

  const updateAllOrdersSelectedState = checked => {
    const updatedOrders = allOrders.map(order => ({ ...order, isSelected: checked }));
    setAllOrders(updatedOrders);
    setSelectAllOrders(checked);
  };

  const updateOrderSelectedState = (id, checked) => {
    const updatedOrders = allOrders.map(order => {
      if (order.id === id) {
        return { ...order, isSelected: checked };
      }
      return order;
    });
    setAllOrders(updatedOrders);
  };

  const changeBulkOrdersStatus = () => {
    newOrders.checkIsBulkChange(true);
    const updatedOrders = allOrders
      .filter(order => order.isSelected)
      .map(order => ({
        id: order.id.toString(),
        delivery_type: order.deliveryType,
      }));
    const payload = {
      variables: {
        storeId,
        orders: updatedOrders,
        desiredStatus: bulkChangeStatus,
        operatorId: user?.id,
      },
    };
    updateBulkOrdersStatus(payload);
    setShowConfirmatioModal(false);
    updateAllOrdersSelectedState(false);
    notifications.show(
      <span>
        <Text value={translations.BULK_CHANGE_NOTIFICATION} payload={numberOfSelectedOrders} className="inline" />
        <Text value={getOrderStatusTranslation(bulkChangeStatus)} className="inline" />
      </span>,
      'success',
    );
  };

  /**
   * Handling orders selection cases
   */
  useEffect(() => {
    if (newOrders?.orders?.length || orders?.data?.orders.orders.length) {
      const pendingOrders = status === ORDER_STATUS.PAST ? newOrders?.orders : [];
      const filteredOrders = [...pendingOrders, ...orders.data.orders.orders];
      const totalOrders = status || statuses ? filteredOrders : [...pendingOrders, ...orders.data.orders.orders];
      let allOrdersWithIsSelectedFlag = [];
      if (selecteAllOrders) {
        allOrdersWithIsSelectedFlag = totalOrders.map(order => ({
          ...order,
          isSelected: !order.updatingStatus?.orderGettingUpdated && selecteAllOrders,
        }));
      } else if (isSelectedOrders) {
        const selectedOrdersIds = selectedOrders.map(selectedOrder => selectedOrder.id);
        allOrdersWithIsSelectedFlag = totalOrders.map(order => {
          const isOrderSelected = selectedOrdersIds.includes(order.id);
          if (isOrderSelected) {
            return { ...order, isSelected: true };
          }
          return order;
        });
      } else {
        allOrdersWithIsSelectedFlag = totalOrders;
      }
      setAllOrders(allOrdersWithIsSelectedFlag);
    }
  }, [newOrders, orders, status, statuses, isSelectedOrders, selecteAllOrders]);

  return !orders?.data || isUpdatingStatus ? (
    <Spinner />
  ) : (
    <>
      {!orders?.data?.orders?.orders?.length && !newOrders?.orders?.length ? (
        <>{!ordersVariables.searchValue ? <NoOrders /> : <ZeroResults />}</>
      ) : (
        <div className="pb-6">
          <BulkChangeConfirmationModal
            showConfirmationModal={showConfirmationModal}
            setShowConfirmatioModal={setShowConfirmatioModal}
            changeBulkOrdersStatus={changeBulkOrdersStatus}
            lang={lang}
          />
          {isSelectedOrders && (
            <OrderSelectionRow
              selectAll={selecteAllOrders}
              allOrders={allOrders}
              setAllOrders={setAllOrders}
              selectedBranchId={selectedBranchId}
              isBulkChange={isBulkChange}
              isPickupOrderSelected={isPickupOrderSelected}
              numberOfSelectedOrders={numberOfSelectedOrders}
              updateAllOrdersSelectedState={updateAllOrdersSelectedState}
              shouldDisableBulkChange={shouldDisableBulkChange}
              setBulkChangeStatus={setBulkChangeStatus}
              setShowConfirmatioModal={setShowConfirmatioModal}
            />
          )}
          {allOrders.length > 0 &&
            allOrders?.map(
              item =>
                ([ORDER_STATUS.FULFILLED, ORDER_STATUS.DELIVERED, ORDER_STATUS.CANCELED].includes(item.status) &&
                  ordersVariables.status === ORDER_STATUS.CURRENT) || (
                  <OrderItem
                    isOpened={openedId === item.id}
                    isOrderUpdating={item.updatingStatus?.orderGettingUpdated}
                    bulkChangeStatus={item.updatingStatus?.nextStatus}
                    isFaded={
                      showOrderStatus &&
                      (!query.search_value || query.search_status === ORDER_STATUS.ALL) &&
                      (item.paymentStatus === ORDER_STATUS.REDIRECT_URL ||
                        item.paymentStatus === ORDER_STATUS.WAITING_FOR_PAYMENT ||
                        item.paymentStatus === ORDER_STATUS.PAYMENT_EXPIRED ||
                        item.paymentStatus === ORDER_STATUS.PAYMENT_FAILED)
                    }
                    onClick={event => {
                      event.stopPropagation();
                      if (openedId !== item.id) {
                        setOpenedId(item.id);
                      } else {
                        setOpenedId(null);
                      }
                      newOrders.markAsRead(item.id);
                    }}
                    key={item.id}
                    ordersVariables={ordersVariables}
                    newOrders={newOrders}
                    isBulkChange={isBulkChange}
                    showOrderStatus={showOrderStatus}
                    orderItemWidth={orderItemWidth}
                    updateOrderSelectedState={updateOrderSelectedState}
                    item={item}
                  />
                ),
            )}
          <div className="flex items-center gap-1 justify-center" style={{ direction }}>
            <HelpIcon width="20px" />
            <Typography variant="body14">{translate(translations.ORDER_WILL_BE_MOVED)}</Typography>
          </div>
          {orders.loading && <Spinner />}
        </div>
      )}
    </>
  );
};

OrderItemView.propTypes = {
  query: PropTypes.shape({
    search_value: PropTypes.string,
    search_status: PropTypes.string,
  }),
  orders: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.shape({
      orders: PropTypes.shape({
        orders: PropTypes.shape({
          discountedAmount: PropTypes.number,
          voucherCode: PropTypes.string,
          subtotalAfterVoucher: PropTypes.number,
          deliveryType: PropTypes.string,
          deliveryFee: PropTypes.number,
          tax: PropTypes.number,
          total: PropTypes.number,
          paidThrough: PropTypes.string,
          voucherAmount: PropTypes.number,
          length: PropTypes.number,
        }),
        totalCount: PropTypes.number,
        statusCount: PropTypes.shape({
          submitted: PropTypes.number.isRequired,
        }),
      }),
    }),
    fetchMore: PropTypes.func,
  }),
  ordersVariables: PropTypes.shape({
    areas: PropTypes.arrayOf(PropTypes.string),
    branchId: PropTypes.string,
    customerName: PropTypes.string,
    deliveryType: PropTypes.arrayOf(PropTypes.string),
    number: PropTypes.number,
    paginateFrom: PropTypes.number,
    paymentMethod: PropTypes.arrayOf(PropTypes.string),
    paymentStatuses: PropTypes.string,
    phone: PropTypes.string,
    status: PropTypes.string,
    statuses: PropTypes.string,
    storeId: PropTypes.string,
    submittedAt: PropTypes.string,
    voucherCode: PropTypes.string,
  }),
  status: PropTypes.string,
  statuses: PropTypes.arrayOf(PropTypes.string),
  paymentStatus: PropTypes.string,
  paymentStatuses: PropTypes.arrayOf(PropTypes.string),
  showOrderStatus: PropTypes.bool,
};
export default OrderItemView;
