import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Text } from 'components/service';
import { FIRST_INCENTIVE, SECOND_INCENTIVE, THIRD_INCENTIVE } from 'constants/translations';

const BorderThrough = ({ active, isBorderFull, position }) => (
  <div
    className={cx(
      'absolute z-10',
      active ? 'bg-primary-base' : 'bg-gray-200',
      isBorderFull ? 'w-full' : 'w-1/2',
      position,
    )}
    style={{
      top: '17px',
      height: '2px',
    }}
  />
);

const StepItem = ({ steps, stepNumber, width, title, isBorderFull, position }) => {
  const isAcitve = steps.includes(stepNumber);
  return (
    <li className={cx('relative flex flex-col items-center justify-center text-center', width && width)}>
      <BorderThrough active={isAcitve} isBorderFull={isBorderFull} position={position} />
      <div
        className={cx(
          'mb-2 w-9 h-9 flex items-center justify-center rounded-full relative z-20',
          isAcitve ? 'bg-primary-base text-white' : 'bg-gray-200 text-gray-500',
        )}
      >
        {stepNumber}
      </div>
      <Text className={cx('text-xs mt-1', !isAcitve && 'text-gray-500')} value={title} />
    </li>
  );
};

const EngageCurrentSteps = ({ direction, steps }) => (
  <ul className="flex items-center mb-4" dir={direction}>
    <StepItem
      steps={steps}
      stepNumber={1}
      position={direction === 'rtl' ? 'right-1/2' : 'left-1/2'}
      width="w-36"
      title={FIRST_INCENTIVE}
    />
    <StepItem
      steps={steps}
      stepNumber={2}
      position={direction === 'rtl' ? 'right-0' : 'left-0'}
      width="w-36"
      isBorderFull
      title={SECOND_INCENTIVE}
    />
    <StepItem
      steps={steps}
      stepNumber={3}
      position={direction === 'rtl' ? 'right-0' : 'left-0'}
      width="w-36"
      title={THIRD_INCENTIVE}
    />
  </ul>
);

EngageCurrentSteps.propTypes = {
  direction: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.number),
};

export default EngageCurrentSteps;
