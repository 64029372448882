export const ENGAGE_TEMPLATE_IDS = {
  WELCOME: 1,
  CASHBACK: 2,
  FREE_DELIVERY: 3,
  VOUCHER_PRECENTAGE: 4,
  VOUCHER_AMOUNT: 5,
  MISS_YOU: 6,
};

export const transformObject = templateValues => {
  const transformedValues = {};

  Object.entries(templateValues).forEach(([templateName, templateData]) => {
    const { staticId, cashback, voucherAmount, voucherPrecentage } = templateData;
    transformedValues[templateName] = {
      incentiveId: staticId,
      amount: Number(cashback) || Number(voucherAmount) || Number(voucherPrecentage) || 0,
    };
  });

  return transformedValues;
};

export const suggestedCashbackAndVoucherValues = [
  { id: 1, value: 5, text: '5%' },
  { id: 2, value: 10, text: '10%' },
  { id: 3, value: 20, text: '20%' },
  { id: 4, value: null, text: 'Other' },
];

export const suggestedVoucherAmountValues = [
  { id: 1, value: 0.25, text: '0.25 KWD' },
  { id: 2, value: 0.5, text: '0.5 KWD' },
  { id: 3, value: 1, text: '1 KWD' },
  { id: 4, value: null, text: 'Other' },
];
