import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Typography, Spinner } from '@zydalabs/zac-react';

import { ORDER_STATUS } from 'constants/order';
import { ORDER_RIDER_STATUS } from 'constants/orderRiderStatus';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { useSelectedStore } from 'hooks';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import Popup from 'components/kit/Popup';
import OrderDeliveryCancellation from 'components/common/orders/OrderDeliveryCancellationPopup';
import Slideover from 'components/common/dashboard/Layout/Slideover/Slideover';
import { useFetchVerdDrivers } from 'service/hooks';
import StoreRiderInfo from './StoreRiderInfo';
import StoreCourierInfo from './StoreCourierInfo';
import useHandleAssignUnAssignDriverToOrder from '../../utils/handleAssignDrivertoOrder';
import useHandleAssignUnAssignCourierToOrder from '../../utils/handleAssignCourierToOrder';
import RestaurantNotHasRiders from './restaurantNotHasRiders';
import AssignDeliveryModal from './AssignDeliveryModal';
import useGetCourierInfoWithLogo from '../../utils/useGetCourierInfoWithLogo';

const OnFleetDeliveryActionButtons = ({ order }) => {
  const { translate } = useContext(localeContext);
  const selectedStoreId = useSelectedStore();
  const { settings, selectedStore } = useContext(userContext);
  const [isDeliverySlideOverOpen, setIsDeliverySlideOverOpen] = useState(false);
  const [isDeliveryCancellationPopupOpen, setIsDeliveryCancellationPopupOpen] = useState(false);

  const { timeZone } = selectedStore;

  const isVerdEnabled = settings?.enableVerd;

  const {
    status: orderStatus,
    branchId,
    deliveryCourier,
    deliveryStatus: deliveryOrderStatus,
    isScheduled: isScheduledOrder,
    firingTime,
    deliveryCourierId,
    expectedAt,
  } = order || {};

  const { data: activeRiders, isLoading: isActiveRidersLoading, mutate: mutateFetchVerdDrivers } = useFetchVerdDrivers({
    storeId: selectedStoreId,
    branchId: parseInt(branchId),
  });

  const {
    courierId: orderDeliveryCourierId,
    driverPhoneNumber: orderDeliveryPhoneNumber,
    trackingLink,
    referenceId,
    driverName: courierDriverName,
    hasDriverInfo: hasCourierDriverInfo,
    driverAssigned: courierDriverAssigned,
    isInternalDelivery,
  } = deliveryCourier || {};

  const riders = activeRiders?.length ? activeRiders.filter(rider => rider.onShift) : [];
  const isOrderCancelled = orderStatus === ORDER_STATUS.CANCELED;
  const isOrderDelivered = orderStatus === ORDER_STATUS.DELIVERED;

  const {
    restaurantCouriersWithLogos,
    isCouriersEstimationsLoading,
    courierEstimationsError,
  } = useGetCourierInfoWithLogo(order);

  const hasCourier = deliveryCourier && deliveryCourierId;
  const isDeliveryOrderStatusCancelledOrDeclined =
    deliveryOrderStatus === ORDER_RIDER_STATUS.CANCELED || deliveryOrderStatus === ORDER_RIDER_STATUS.DECLINED;
  const isOrderDeliveredOrCancelled = orderStatus === ORDER_STATUS.DELIVERED || orderStatus === ORDER_STATUS.CANCELED;

  const assignedDriver = activeRiders?.find(driver => driver.phone === orderDeliveryPhoneNumber);
  const assignedCourier = restaurantCouriersWithLogos?.find(
    courier => Number(courier.courierId) === orderDeliveryCourierId,
  );

  const isOrderAssignedToCourierOrRider = !!assignedDriver?.id || !!assignedCourier?.courierId;
  const showDeliveryExpectedAt =
    !isDeliveryOrderStatusCancelledOrDeclined && !isOrderDeliveredOrCancelled && hasCourier;

  const {
    handleAssignCourierToOrder,
    handleUnAssignCourierToOrder,
    isCourierInfoLoading,
  } = useHandleAssignUnAssignCourierToOrder({
    order,
    mutateFetchVerdDrivers,
  });

  const {
    handleAssignDriverToOrder,
    handleUnassignDriverToOrder,
    isRiderInfoLoading,
  } = useHandleAssignUnAssignDriverToOrder({
    order,
    mutateFetchVerdDrivers,
  });

  const isAssignDeliveryButtonLoading = isRiderInfoLoading || isCourierInfoLoading;

  if (isActiveRidersLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col">
        {!isRiderInfoLoading && !!assignedDriver && (
          <StoreRiderInfo
            deliveryOrderStatus={deliveryOrderStatus}
            orderDeliveryPhoneNumber={orderDeliveryPhoneNumber}
            assignedDriver={assignedDriver}
          />
        )}
        {!isCourierInfoLoading && !!assignedCourier && (
          <StoreCourierInfo
            order={order}
            deliveryOrderStatus={deliveryOrderStatus}
            referenceId={referenceId}
            hasDriverInfo={hasCourierDriverInfo}
            driverAssigned={courierDriverAssigned}
            orderDeliveryPhoneNumber={orderDeliveryPhoneNumber}
            driverName={courierDriverName}
            trackingLink={trackingLink}
            isScheduledOrder={isScheduledOrder}
            firingTime={firingTime}
            assignedCourier={assignedCourier}
          />
        )}
        <div className="gap-2 flex mb-4">
          {((isVerdEnabled && riders?.length !== 0) || restaurantCouriersWithLogos?.length !== 0) &&
            !isOrderCancelled && (
              <Button
                isDisabled={isOrderDelivered}
                variant="tertiary"
                text={translate(
                  isOrderAssignedToCourierOrRider ? translations.CHANGE_DELIVERY : translations.ASSIGN_DELIVERY,
                )}
                rounded
                isLoading={isAssignDeliveryButtonLoading}
                isFluid={isOrderAssignedToCourierOrRider}
                onClick={() => setIsDeliverySlideOverOpen(true)}
              />
            )}
          {isOrderAssignedToCourierOrRider && (
            <Button
              variant="secondary"
              rounded
              isFluid
              isLoading={isRiderInfoLoading || isCourierInfoLoading}
              text={translate(translations.UNASSIGN)}
              onClick={() =>
                assignedDriver
                  ? handleUnassignDriverToOrder(assignedDriver)
                  : !isInternalDelivery && deliveryOrderStatus !== ORDER_RIDER_STATUS.DECLINED
                  ? setIsDeliveryCancellationPopupOpen(true)
                  : handleUnAssignCourierToOrder()
              }
            />
          )}
        </div>
        {riders?.length === 0 && !assignedCourier && (
          <RestaurantNotHasRiders
            mutateFetchVerdDrivers={mutateFetchVerdDrivers}
            handleAssignDriverToOrder={handleAssignDriverToOrder}
          />
        )}
      </div>
      {showDeliveryExpectedAt && (
        <div className="pt-3 border-t">
          <Typography variant="heading14">
            <Text value={translations.EXPECTED_DELIVERY_TIME} />
          </Typography>
          <span className="text-sm">
            {moment(expectedAt)
              .tz(timeZone)
              .format('MMMM DD, YYYY - h:mm a')}
          </span>
        </div>
      )}
      <Slideover
        isOpen={isDeliverySlideOverOpen}
        body={
          <AssignDeliveryModal
            order={order}
            mutateFetchVerdDrivers={mutateFetchVerdDrivers}
            close={() => setIsDeliverySlideOverOpen(false)}
            handleAssignDriverToOrder={handleAssignDriverToOrder}
            handleAssignCourierToOrder={handleAssignCourierToOrder}
            assigned={assignedDriver || assignedCourier}
            restaurantCouriersWithLogos={restaurantCouriersWithLogos}
            riders={riders}
            isCouriersEstimationsLoading={isCouriersEstimationsLoading && !courierEstimationsError}
          />
        }
      />
      {isDeliveryCancellationPopupOpen && (
        <Popup heading={translations.CONFIRM_CANCELLATION} close={setIsDeliveryCancellationPopupOpen} size="max-w-xl">
          <OrderDeliveryCancellation
            order={order}
            unAssignDriverToOrder={handleUnAssignCourierToOrder}
            assignee={assignedCourier}
            close={setIsDeliveryCancellationPopupOpen}
          />
        </Popup>
      )}
    </div>
  );
};

OnFleetDeliveryActionButtons.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number,
    number: PropTypes.string,
    status: PropTypes.string.isRequired,
    deliveryTime: PropTypes.number,
    branchData: PropTypes.shape({ id: PropTypes.string }),
    restaurantRiderBranchOrderData: PropTypes.shape({ restaurantRider: PropTypes.string, status: PropTypes.string }),
    deliveryCourierId: PropTypes.number,
    deliveryStatus: PropTypes.string,
    isScheduled: PropTypes.bool,
    firingTime: PropTypes.number,
    expectedAt: PropTypes.string,
    deliveryCourier: PropTypes.shape({
      courierId: PropTypes.number,
      driverPhoneNumber: PropTypes.string,
      deliveryCourierId: PropTypes.number,
      trackingLink: PropTypes.string,
      referenceId: PropTypes.string,
      driverName: PropTypes.string,
      hasDriverInfo: PropTypes.bool,
      driverAssigned: PropTypes.string,
    }),
  }),
};
export default OnFleetDeliveryActionButtons;
