import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveLine } from '@nivo/line';
import { Typography } from '@zydalabs/zac-react';
import moment from 'moment';

const SalesLineChart = ({ data }) => {
  const dataKeys = Object.keys(data);
  const startDate = dataKeys[0];
  const endData = dataKeys[dataKeys.length - 1];

  const chartData = Object.entries(data).map(([, value], index) => ({
    x: index + 1,
    y: value,
  }));

  return (
    <>
      <div className="h-24">
        <ResponsiveLine
          curve="linear"
          enableArea
          enablePoints={false}
          enableGridX={false}
          enableGridY={false}
          colors={{ scheme: 'accent' }}
          axisLeft={{
            tickValues: [],
          }}
          data={[{ data: chartData }]}
        />
      </div>
      <div className="flex items-center justify-between mt-3" style={{ direction: 'ltr' }}>
        <Typography variant="body12">{moment(startDate, 'YYYY-MM-DD').format('D MMMM')}</Typography>
        <Typography variant="body12">{moment(endData, 'YYYY-MM-DD').format('D MMMM')}</Typography>
      </div>
    </>
  );
};

SalesLineChart.propTypes = {
  data: PropTypes.shape({}),
};

export default SalesLineChart;
