import { engageCurrentSettings } from 'service/queries';
import { QueryCurrentEngageSettingsArgs, CurrentEngageSettings } from 'service/types/generated/backend_client_index';
import { GRAPHQL_CLIENT_NAMES } from 'service/constants';
import * as userService from 'services/user';
import { useQuery } from '../../utils';
import { QueryHookResponse } from '../../types/internal';

type useFetchCurrentEngageSettingsType = (
  args: QueryCurrentEngageSettingsArgs,
) => QueryHookResponse<CurrentEngageSettings>;

const useFetchCurrentEngageSettings: useFetchCurrentEngageSettingsType = ({ subdomain }) => {
  const token = userService.getToken();
  return useQuery<CurrentEngageSettings>({
    query: engageCurrentSettings,
    variables: { subdomain },
    clientName: GRAPHQL_CLIENT_NAMES.BACKEND,
    requestHeaders: { Authorization: `Bearer ${token}` },
    handler: (res: any) => res,
    config: { revalidateOnMount: true },
  });
};

export default useFetchCurrentEngageSettings;
