import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@zydalabs/zac-react';

import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import { context as localeContext } from 'context/locale';
import {
  CANCEL,
  CONFIRM,
  DEACTIVATE_ENGAGE_MESSAGE,
  DEACTIVATE_ENGAGE_CONFIRM_MESSAGE,
  SOMETHING_WENT_WRONG,
} from 'constants/translations';
import { Text } from 'components/service';
import { useCreateEngageSetting } from 'service/hooks/engage';

const DeactivateConfirmModal = ({ close, setEngageActive }) => {
  const { selectedStore } = useContext(userContext);
  const { translate } = useContext(localeContext);
  const notifications = useContext(notificationsContext);
  const { subdomain } = selectedStore;
  const createEngageSetting = useCreateEngageSetting();

  const [disableDeactivateButton, setDisableDeactivateButton] = useState(false);

  // handle deactivate
  const handleDeactivate = async () => {
    setDisableDeactivateButton(true);
    const response = await createEngageSetting({
      subdomain,
      enabled: false,
    });

    if (response.hasError) {
      setDisableDeactivateButton(false);
      notifications.show(<Text value={SOMETHING_WENT_WRONG} />, 'error');
    } else {
      setDisableDeactivateButton(false);
      setEngageActive(false);
      notifications.show(<Text value={DEACTIVATE_ENGAGE_CONFIRM_MESSAGE} />, 'success');
      close();
    }
  };

  return (
    <>
      <Typography variant="body14" mb={32}>
        <Text value={DEACTIVATE_ENGAGE_MESSAGE} />
      </Typography>

      <div className="flex justify-end gap-2">
        <Button rounded variant="secondary" text={translate(CANCEL)} onClick={close} />
        <Button
          rounded
          variant="primary"
          text={translate(CONFIRM)}
          disabled={disableDeactivateButton}
          isLoading={disableDeactivateButton}
          onClick={handleDeactivate}
        />
      </div>
    </>
  );
};

DeactivateConfirmModal.propTypes = {
  close: PropTypes.func.isRequired,
  setEngageActive: PropTypes.func.isRequired,
};

export default DeactivateConfirmModal;
