/* eslint-disable jsx-a11y/media-has-caption */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@zydalabs/zac-react';
import { ArrowRightIcon, ArrowLeftIcon } from '@zydalabs/zac-icons-react';

import { context as localeContext } from 'context/locale';
import ImageView from 'assets/engage-phone.svg';
import { Text } from 'components/service';
import {
  SEE_HOW_IT_WORKS,
  WHATSAPP_MARKET_AUTOPILOT,
  WHATSAPP_MARKET_AUTOPILOT_MESSAGE,
  ACTIVATE_WHATSAPP_AUTOPILOT,
} from 'constants/translations';
import Popup from 'components/kit/Popup';

const EngageWizard = ({ engageActive, open }) => {
  const { translate, lang, direction } = useContext(localeContext);
  const [showEngageVideo, setShowEngageVideo] = useState(false);

  return (
    <div className="bg-zyda-purple-50 p-5 md:flex md:flex-row-reverse items-center justify-between">
      <div>
        <img src={ImageView} alt="imageview" />
      </div>
      <div className="md:w-3/6 max-w-lg p-4" dir={direction}>
        <Typography variant="heading24">
          <Text value={WHATSAPP_MARKET_AUTOPILOT} />
        </Typography>

        <Typography variant="body16" mt={12}>
          <Text value={WHATSAPP_MARKET_AUTOPILOT_MESSAGE} />
        </Typography>
        <div className="mt-5">
          <Button
            text={translate(SEE_HOW_IT_WORKS)}
            variant="tertiary"
            rounded
            onClick={() => {
              setShowEngageVideo(true);
            }}
          />
        </div>
        {!engageActive && (
          <div className="mt-4">
            <Button
              isFluid
              text={translate(ACTIVATE_WHATSAPP_AUTOPILOT)}
              variant="primary"
              rounded
              onClick={open}
              endIcon={lang === 'ar' ? <ArrowLeftIcon width="20px" /> : <ArrowRightIcon width="20px" />}
            />
          </div>
        )}
      </div>

      {showEngageVideo && (
        <Popup enableOutsideClick preventClosure size="max-w-2xl" close={() => setShowEngageVideo(false)}>
          <div style={{ margin: '-1rem', backgroundColor: '#fff' }}>
            <video src="https://s3.eu-west-1.amazonaws.com/static.zyda.com/engage.mp4" controls autoPlay>
              Your browser does not support the video tag.
            </video>
          </div>
        </Popup>
      )}
    </div>
  );
};

EngageWizard.propTypes = {
  engageActive: PropTypes.bool.isRequired,
  open: PropTypes.func.isRequired,
};

export default EngageWizard;
