import { gql } from 'graphql-request';

const createEngageSettingMutation = gql`
  mutation($subdomain: String!, $enabled: Boolean!, $setting: EngageIncentiveSetting) {
    toggleEngageSettings(subdomain: $subdomain, enabled: $enabled, settings: $setting) {
      success
    }
  }
`;

export default createEngageSettingMutation;
