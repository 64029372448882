import React, { useState, useContext, useRef } from "react"
import * as R from "ramda"
import { DropDown } from "components/kit"
import { Text } from "components/service"
import * as translations from "constants/translations"
import { context as localeContext } from "context/locale"
import { Error } from "components/form/generic"
import cx from "classnames"

export default ({
  error,
  label,
  left,
  right,
  dropdown,
  prepTime,
  options,
  name,
  testId,
  value,
  noBorder,
  width,
  height = "h-10",
  validation = R.T,
  disabled,
  onChange,
  testId_openDropDown,
  testId_selectedData,
  testId_listData,
  testId_stepUp,
  testId_stepDown,
  hideStepper = false,
  ...props
}) => {
  const [isFocused, setFocused] = useState(false)
  const { lang, direction } = useContext(localeContext)

  const [optionSelected, setOptionSelected] = useState(
    !dropdown ? "hrs" : value && value[1]
  )
  const ref = useRef()
  return (
    <div className="flex flex-col w-full">
      <div
        style={{ direction: direction }}
        className={cx(
          "flex-1 flex flex-row justify-center items-center h-full rounded-none",
          noBorder ? "" : "border",
          disabled && "cursor-not-allowed pointer-events-none opacity-50",
          error
            ? "border-zyda-red-500"
            : isFocused
            ? "border-primary-200"
            : "border-gray-300",
          width && `w-${width}`
        )}
      >
        {left && (
          <div className="flex -mr-px">
            <span className="flex-2 flex justify-center items-center whitespace-nowrap h-10 w-10 rounded-l bg-gray-200 text-gray-500 left-0 text-center text-xs">
              {left}
            </span>
          </div>
        )}
        <input
          style={{ direction, styles }}
          ref={ref}
          onKeyPress={e => {
            e.key === "Enter" && e.preventDefault()
          }}
          className={
            "flex-shrink flex-grow flex-auto bg-transparent text-zyda-black-100 appearance-none rounded-l w-full " +
            height +
            " px-3 text-gray-700 leading-tight focus:outline-none"
          }
          type="number"
          name={name}
          data-testid={testId}
          value={!dropdown ? value : value && value[0]}
          onChange={e => {
            if (onChange && validation(e.target.value)) {
              !dropdown
                ? onChange(e)
                : onChange({
                    target: {
                      name,
                      value: [e.target.value, value[1]],
                    },
                  })
            }
          }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          {...props}
        />
        {dropdown ? (
          <div className="flex -ml-px">
            <DropDown
              testId_openDropDown={testId_openDropDown}
              testId_selectedData={testId_selectedData}
              testId_listData={testId_listData}
              float={"right"}
              icon="expand_more"
              optionSelected={optionSelected}
              onSelect={(option, e) => {
                setOptionSelected(option)
                onChange({
                  target: {
                    name,
                    value: [value[0], option],
                  },
                })
              }}
              data={options}
              bg
              noBorder
              position={lang === "ar" ? "right" : "left"}
              combined
              full
            />
          </div>
        ) : (
          right && (
            <div className={cx("flex", prepTime ? "px-2" : "-mr-px")}>
              <span className="flex-2 flex justify-center items-center whitespace-nowrap h-10 w-10 rounded-l bg-gray-200 text-gray-500 left-0 text-center text-xs">
                {right}
              </span>
            </div>
          )
        )}
        {
          !hideStepper && <div className="flex -ml-px">
          <div className="flex flex-col items-center leading-normal bg-grey-lighter w-full whitespace-nowrap text-grey-dark text-sm">
            <button
              type="button"
              className={cx(
                "whitespace-nowrap border-b relative group flex items-center justify-center outline-none bg-gray-200 border-gray-300 hover:bg-zyda-grey-100",
                lang === "ar"
                  ? "rounded-tl border-r border-l"
                  : "rounded-tr border-l"
              )}
              data-testid={testId_stepUp}
              disabled={disabled}
              style={{ outline: 0 }}
              onClick={() => {
                ref.current.stepUp(1)
                onChange({
                  target: {
                    name,
                    value: dropdown
                      ? [ref.current.valueAsNumber, value[1]]
                      : ref.current.valueAsNumber,
                  },
                })
              }}
            >
              <i className="material-icons text-xl text-gray-500 px-2">
                arrow_drop_up
              </i>
            </button>
            <button
              type="button"
              className={cx(
                "whitespace-nowrap relative group flex items-center justify-center outline-none bg-gray-200 border-gray-300 hover:bg-zyda-grey-100",
                lang === "ar"
                  ? "rounded-bl border-r border-l"
                  : "rounded-br border-l"
              )}
              data-testid={testId_stepDown}
              disabled={disabled}
              style={{ outline: 0 }}
              onClick={() => {
                ref.current.stepDown(1)
                onChange({
                  target: {
                    name,
                    value: dropdown
                      ? [ref.current.valueAsNumber, value[1]]
                      : ref.current.valueAsNumber,
                  },
                })
              }}
            >
              <i className="material-icons text-xl text-gray-500 px-2">
                arrow_drop_down
              </i>
            </button>
          </div>
        </div>
        }
        
      </div>
      {error && <Error>{error}</Error>}
    </div>
  )
}
const styles = {
  MozAppearance: "textfield",
  "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },
}
