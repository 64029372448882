import { gql } from '@apollo/client';

export const PAYMENT_CREDENTIALS = gql`
  query($storeId: String!) {
    paymentCredentials(restaurantId: $storeId) {
      restaurantPaymentMethods {
        enabled
      }
    }
  }
`;

export const CASHBACK_PROGRAMS_LIST = gql`
  query($subdomain: String!) {
    cashbackPrograms(subdomain: $subdomain) {
      endsAt
      status
    }
  }
`;
