import React, { useContext, useState } from 'react';
import { Typography, Button, TextInput, useTheme } from '@zydalabs/zac-react';
import { ArrowLeftIcon, HelpIcon } from '@zydalabs/zac-icons-react';
import { useQuery } from '@apollo/client';
import { Formik, Form } from 'formik';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'style.css';

import { Field } from 'components/service';
import * as schemas from 'components/service/Page/schemas';
import * as translations from 'constants/translations';
import * as userService from 'services/user';
import { context as notificationsContext } from 'context/notifications';
import { useSelectedStore } from 'hooks/index';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { useUpdateStoreCourierSettings } from 'service/hooks';
import { RadioList } from 'components/form/elements';
import EnableVerdSetting from '../../../components/common/verd/EnableVerdSetting';

const SettingsPage = ({ setIsSettingsOpened }) => {
  const {
    selectedStore: { restaurantCourierSetting, id, countryCode },
    setSelectedStore,
    settings: { enableVerd } = {},
    user: { isSupport },
  } = useContext(userContext);
  const { translate, lang, direction } = useContext(localeContext);
  const notifications = useContext(notificationsContext);
  const selectedStoreId = useSelectedStore();
  const { colors } = useTheme();
  const updateStoreCourierSettings = useUpdateStoreCourierSettings();
  const verd = restaurantCourierSetting?.find(courier => courier.isInternalDelivery);
  const { driverMaxCapacity, autoAssignMechanism, courierId, assignmentFlow, networkFees } = verd || {};

  const [isLoading, setIsLoading] = useState(false);

  const textAndTooltipComponent = (text, tooltipText) => (
    <div className="flex gap-1 items-center">
      <Typography cursor="pointer">{translate(text)}</Typography>
      {tooltipText && (
        <>
          <HelpIcon
            width="18px"
            color="#c1c1c1"
            data-tooltip-id="tooltip"
            data-tooltip-content={translate(tooltipText)}
          />
          <ReactTooltip place="top" id="tooltip" style={{ maxWidth: 'fit-content' }} />
        </>
      )}
    </div>
  );

  const autoAssignRiderStates = [
    {
      value: 'ROUND_ROBIN',
      title: textAndTooltipComponent(translations.ROUND_ROBIN, translations.ROUND_ROBIN_DESC),
    },
    {
      value: 'AUTO_ASSIGN_IF_COURIER_FAIL',
      title: textAndTooltipComponent(translations.AFTER_COURIER_FAILS, translations.AFTER_COURIER_FAILS_DESC),
    },
    { value: 'DO_NOT_ASSIGN', title: textAndTooltipComponent(translations.DO_NOT_AUTO_ASSIGN) },
  ];

  const assignmentFlowStates = [
    {
      value: 'NORMAL_FLOW',
      title: textAndTooltipComponent(translations.DEFAULT_FLOW, translations.DEFAULT_FLOW_DESC),
    },
    {
      value: 'QUICK_FLOW',
      title: textAndTooltipComponent(translations.QUICK_ASSIGN_FLOW, translations.QUICK_ASSIGN_FLOW_DESC),
    },
  ];

  const { refetch } = useQuery(schemas.USER, {
    variables: {
      storeId: id,
      id: userService.getUserId(),
    },
    onCompleted: data => {
      setSelectedStore(data.restaurant);
      setIsLoading(false);
    },
    fetchPolicy: 'cache-and-network',
  });

  const onSubmit = async data => {
    setIsLoading(true);
    const res = await updateStoreCourierSettings({
      ...data,
      autoAssignMechanism: data.autoAssignMechanism.toUpperCase(),
      assignmentFlow: data.assignmentFlow.toUpperCase(),
      driverMaxCapacity: parseInt(data.driverMaxCapacity),
      networkFees: parseFloat(data.networkFees) / 1000,
    });
    if (res?.errors?.length) {
      notifications.show(<Typography>{[res?.errors?.[0]?.message]}</Typography>, 'error');
      setIsLoading(false);
    } else {
      await refetch();
      setIsSettingsOpened(false);
    }
  };

  return (
    <Formik
      initialValues={{
        autoAssignMechanism: autoAssignMechanism?.toUpperCase(),
        assignmentFlow: assignmentFlow?.toUpperCase(),
        courierId: parseInt(courierId),
        driverMaxCapacity: parseInt(driverMaxCapacity),
        networkFees: parseFloat(networkFees) * 1000,
        storeId: parseInt(selectedStoreId),
      }}
      onSubmit={onSubmit}
    >
      {({ setFieldValue }) => (
        <Form>
          <div className="relative mx-6 flex flex-col">
            <div className="sticky top-0 flex items-center border-b pb-4 justify-between bg-white pt-6 z-10">
              <div className="flex items-center gap-2 cursor-pointer">
                <ArrowLeftIcon width="20px" color="black" onClick={() => setIsSettingsOpened(false)} />
                <Typography variant="heading20">{translate(translations.SETTINGS)}</Typography>
              </div>
              <Button
                type="submit"
                disabled={!enableVerd}
                text={translate(translations.SAVE)}
                isLoading={isLoading}
                rounded
                onClick={() => ({})}
              />
            </div>
            <div style={{ direction }}>
              <div className="mt-7">
                <div className="flex flex-row items-start justify-between">
                  <div>
                    <Typography variant="heading16">{translate(translations.ENABLE_VERD)}</Typography>
                    <Typography variant="body14" color={colors.gray[500]}>
                      {translate(translations.ENABLE_VERD_DESC)}
                    </Typography>
                  </div>
                  <EnableVerdSetting setFieldValue={setFieldValue} />
                </div>
              </div>
              {enableVerd && (
                <>
                  <div className="mt-7 pt-9 border-t">
                    <div>
                      <Typography variant="heading16">{translate(translations.RIDER_CAPACITY)}</Typography>
                      <Typography variant="body14" color={colors.gray[500]}>
                        {translate(translations.RIDER_CAPACITY_DESC)}
                      </Typography>
                      <div className="w-3/5">
                        <div className="text-input mt-6 ">
                          <Field
                            name="driverMaxCapacity"
                            isDisabled={isLoading}
                            label={translate(translations.MAX_ORDER_PER_RIDERS)}
                            component={TextInput}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-7 pt-9 border-t">
                    <div>
                      <Typography variant="heading16">{translate(translations.AUTO_ASSIGN_RIDER)}</Typography>
                      <Typography variant="body14" color={colors.gray[500]}>
                        {translate(translations.AUTO_ASSIGN_RIDER_DESC)}
                      </Typography>
                      <Field
                        type="text"
                        name="autoAssignMechanism"
                        items={autoAssignRiderStates || []}
                        bodyElementClassName="py-2"
                        elementClassName="py-1"
                        elementStyle={{
                          direction: lang === 'ar' ? 'rtl' : 'unset',
                        }}
                        containerStyle={{
                          marginTop: '20px',
                        }}
                        component={RadioList}
                      />
                    </div>
                  </div>
                  <div className="mt-7 pt-9 border-t">
                    <div>
                      <Typography variant="heading16">{translate(translations.WHATSAPP_FLOW)}</Typography>
                      <Typography variant="body14" color={colors.gray[500]}>
                        {translate(translations.WHATSAPP_FLOW_DESC)}
                      </Typography>
                      <Field
                        type="text"
                        name="assignmentFlow"
                        items={assignmentFlowStates || []}
                        bodyElementClassName="py-2"
                        elementClassName="py-1"
                        elementStyle={{
                          direction: lang === 'ar' ? 'rtl' : 'unset',
                        }}
                        containerStyle={{
                          marginTop: '20px',
                        }}
                        component={RadioList}
                      />
                    </div>
                  </div>
                  {isSupport && countryCode === 'KW' && (
                    <div className="mt-7 py-9 border-t">
                      <Typography variant="heading16">{translate(translations.VERD_FEES)}</Typography>
                      <div className="flex w-2/5 items-center mt-6 gap-1">
                        <div className="text-input">
                          <Field name="networkFees" isDisabled={isLoading} component={TextInput} />
                        </div>
                        <Typography>{translate(translations.FILS)}</Typography>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SettingsPage;
