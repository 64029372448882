import React from 'react';
import * as yup from 'yup';

import { Text } from 'components/service';
import { MAX_100, MAX_100_AMOUNT, MIN_0_1_AMOUNT, MIN_1, REQUIRED } from 'constants/translations';

const requiredText = <Text value={REQUIRED} className="text-red-600 text-xs mt-1" />;

const templateSchema = yup.object().shape({
  id: yup.string().required(requiredText),
  hasVoucherPrecentage: yup.boolean(),
  voucherPrecentage: yup.number().when('hasVoucherPrecentage', {
    is: true,
    then: yup
      .number()
      .typeError(requiredText)
      .min(1, <Text value={MIN_1} className="text-red-600 text-xs mt-1" />)
      .max(100, <Text value={MAX_100} className="text-red-600 text-xs mt-1" />)
      .required(requiredText)
      .transform((value, originalValue) => {
        // Handle empty strings and convert them to null
        if (originalValue === '') {
          return null;
        }
        return value;
      }),
  }),
  hasVoucherAmount: yup.boolean(),
  voucherAmount: yup.number().when('hasVoucherAmount', {
    is: true,
    then: yup
      .number()
      .typeError(requiredText)
      .min(0.1, <Text value={MIN_0_1_AMOUNT} className="text-red-600 text-xs mt-1" />)
      .max(100, <Text value={MAX_100_AMOUNT} className="text-red-600 text-xs mt-1" />)
      .required(requiredText)
      .transform((value, originalValue) => {
        // Handle empty strings and convert them to null
        if (originalValue === '') {
          return null;
        }
        return value;
      }),
  }),
  hasCashback: yup.boolean(),
  cashback: yup.number().when('hasCashback', {
    is: true,
    then: yup
      .number()
      .typeError(requiredText)
      .min(1, <Text value={MIN_1} className="text-red-600 text-xs mt-1" />)
      .max(100, <Text value={MAX_100} className="text-red-600 text-xs mt-1" />)
      .required(requiredText)
      .transform((value, originalValue) => {
        // Handle empty strings and convert them to null
        if (originalValue === '') {
          return null;
        }
        return value;
      }),
  }),
});

export const engageValidationSchema = yup.object().shape({
  engage1Settings: yup.object().when('step1', {
    is: true,
    then: templateSchema,
  }),
  engage2Settings: yup.object().when('step2', {
    is: true,
    then: templateSchema,
  }),
  engage3Settings: yup.object().when('step3', {
    is: true,
    then: templateSchema,
  }),
});
