import React, { useContext, useState } from 'react';
import { navigate } from '@reach/router';
import { useMutation, useQuery } from '@apollo/client';
import { Formik, Form } from 'formik';
import cx from 'classnames';

import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { useSelectedStore, useReplaceParams, useTablet } from 'hooks/index';
import { context as customersContext } from 'context/customers';
import { context as userContext } from 'context/user';
import { context as localContext } from 'context/locale';
import { context as notificationsContext } from 'context/notifications';
import { ReactComponent as CancelCampaignIcon } from 'assets/cancel-campaign.svg';
import * as translations from 'constants/translations';
import DATE_RANGES from 'constants/date';
import { Text } from 'components/service';
import { Button, Stack } from 'components/kit';
import SecondaryWizard from 'components/common/SecondaryWizard';
import * as paths from 'paths.js';
import { AVG_ORDER_VALUE, TOTAL_ORDERS } from 'pages/analytics/Main/schemas';
import { parseRange, stringifyRange, getRange, toRangeVariable } from 'utils/date';
import * as schemas from './schemas';
import { CAMPAIGN_TYPES, CAMPAIGN_OBJECT_KEYS, CAMPAIGN_STATES, CREDIT_BALANCE_STATE, TAB_NAMES } from './constants';
import CampaignTypeStep from './CampaignTypeStep';
import CampaignAudienceStep from './CampaignAudienceStep';
import CampaignDetailsStep from './CampaignDetailsStep';
import CampaignReviewStep from './CampaignReviewStep';
import { initialValues, createValidationSchemaCreation } from './data';
import { prepareCampaignObject, calculateCreditBalanceRatio } from './utils';
import SenderId from '../SenderId';

const CreateCampaign = () => {
  const { selectedSegment, fileController, audienceType } = useContext(customersContext);
  const { selectedStore } = useContext(userContext);
  const { lang, translate } = useContext(localContext);
  const notifications = useContext(notificationsContext);
  const storeId = useSelectedStore();
  const replace = useReplaceParams();
  const isTablet = useTablet();

  const [hasActiveConflictingCampaign, setHasActiveConflictingCampaign] = useState(false);

  const handleCreateCampaignOnError = error => {
    const body = error?.graphQLErrors[0]?.extensions?.exception?.body;
    if (body)
      Object.keys(body).forEach(key => {
        notifications.show(`${body[key][0]}`, 'error');
      });
    else notifications.show(translations.SOMETHING_WENT_WRONG, 'error');
    setHasActiveConflictingCampaign(true);
  };

  const range = toRangeVariable(parseRange(stringifyRange(getRange(DATE_RANGES.THIRTY_DAYS))));

  const {
    data: {
      analyticsAvgOrderValue: {
        currentPeriod: { data: averageOrderValueObject },
      },
    } = {
      analyticsAvgOrderValue: {
        currentPeriod: { averageOrderValueObject: { month: -1 } },
      },
    },
  } = useQuery(AVG_ORDER_VALUE, {
    variables: {
      storeId,
      range,
      date: 'month',
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const { data: totalOrdersQueryResponseData } = useQuery(TOTAL_ORDERS, {
    variables: {
      storeId,
      range,
      date: 'month',
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });
  const { analyticsTotalOrders } = totalOrdersQueryResponseData || {};
  const { currentPeriod: totalOrdersCurrentPeriod } = analyticsTotalOrders || {};
  const { total: totalOrders } = totalOrdersCurrentPeriod || {};

  let averageOrderValue = -1;
  if (averageOrderValueObject) averageOrderValue = averageOrderValueObject[Object.keys(averageOrderValueObject)[0]];

  const [createCampaign] = useMutation(schemas.CREATE_CAMPAIGN, {
    variables: {
      restaurantId: storeId,
    },
  });

  const campaignWizardStepNames = {
    CAMPAIGN_TYPE: 'CampaignType',
    CAMPAIGN_AUDIENCE: 'CampaignAudience',
    CAMPAIGN_DETAILS: 'CampaignDetails',
    CAMPAIGN_REVIEW: 'CampaignReview',
  };

  const createCampaignWizardSteps = [
    {
      name: campaignWizardStepNames.CAMPAIGN_TYPE,
      fields: [{ name: CAMPAIGN_OBJECT_KEYS.TITLE }, { name: CAMPAIGN_OBJECT_KEYS.TYPE }],
    },
    {
      name: campaignWizardStepNames.CAMPAIGN_AUDIENCE,
      fields: [
        { name: CAMPAIGN_OBJECT_KEYS.INACTIVITY_PERIOD },
        { name: CAMPAIGN_OBJECT_KEYS.LOOKBACK_WINDOW },
        { name: CAMPAIGN_OBJECT_KEYS.SMS_SENDING_LIMIT },
        { name: CAMPAIGN_OBJECT_KEYS.TARGETED_SEGMENT },
        { name: CAMPAIGN_OBJECT_KEYS.ESTIMATE_REACH },
      ],
    },
    {
      name: campaignWizardStepNames.CAMPAIGN_DETAILS,
      fields: [
        { name: CAMPAIGN_OBJECT_KEYS.VOUCHER_AMOUNT },
        { name: CAMPAIGN_OBJECT_KEYS.VALID_FOR },
        { name: CAMPAIGN_OBJECT_KEYS.VOUCHER_MIN_SUBTOTAL_AMOUNT },
        { name: CAMPAIGN_OBJECT_KEYS.VOUCHER_REDEMPTION_LIMIT_PER_USER },
        { name: CAMPAIGN_OBJECT_KEYS.STARTS_AT },
        { name: CAMPAIGN_OBJECT_KEYS.EXPIRES_AT },
        { name: CAMPAIGN_OBJECT_KEYS.SMS_BODY },
      ],
    },
    {
      name: campaignWizardStepNames.CAMPAIGN_REVIEW,
      fields: [],
    },
  ];

  const [createCampaignLoader, setCreateCampaignLoader] = useState(false);
  const handleOnSubmit = async data => {
    setCreateCampaignLoader(true);
    const campaignData = prepareCampaignObject(data, CAMPAIGN_STATES.ACTIVE);
    try {
      await createCampaign({
        variables: {
          ...campaignData,
        },
      });
      setCreateCampaignLoader(false);
      navigate(replace(paths.campaigns));
    } catch (err) {
      setCreateCampaignLoader(false);
      handleCreateCampaignOnError(err);
    }
  };

  const handleAddSmsCredits = async values => {
    try {
      await createCampaign({
        variables: {
          ...prepareCampaignObject(values, CAMPAIGN_STATES.DRAFT),
        },
      });
      navigate(replace(paths.campaignSubscriptions));
    } catch (err) {
      handleCreateCampaignOnError(err);
    }
  };

  const resetValuesOnTypeChange = (campaignType, setFieldValue) => {
    setFieldValue(CAMPAIGN_OBJECT_KEYS.ESTIMATE_REACH, 0);
    setFieldValue(CAMPAIGN_OBJECT_KEYS.SMS_BODY, '');
    if (campaignType === CAMPAIGN_TYPES.RETENTION) {
      setFieldValue(CAMPAIGN_OBJECT_KEYS.CAMPAIGN_GOAL, undefined);
      setFieldValue(CAMPAIGN_OBJECT_KEYS.TARGETED_SEGMENT, undefined);
    }
    if (campaignType === CAMPAIGN_TYPES.SEGMENT_TARGETING || CAMPAIGN_TYPES.ANNOUNCEMENT) {
      setFieldValue(CAMPAIGN_OBJECT_KEYS.ESTIMATE_REACH_WITH_SENDING_LIMIT, 0);
      setFieldValue(CAMPAIGN_OBJECT_KEYS.INACTIVITY_PERIOD, undefined);
      setFieldValue(CAMPAIGN_OBJECT_KEYS.SMS_SENDING_LIMIT, undefined);
      setFieldValue(CAMPAIGN_OBJECT_KEYS.EXPIRES_AT, undefined);
    }
  };

  const handleOnCreatingCampaign = async (validateForm, setFieldTouched, values) => {
    let isValid;
    try {
      const result = await validateForm();
      const invalidFields = Object.keys(result);
      isValid = invalidFields.length === 0;
      if (isValid) {
        try {
          await createCampaign({
            variables: {
              ...prepareCampaignObject(values, CAMPAIGN_STATES.DRAFT),
            },
          });
          navigate(replace(paths.campaigns));
        } catch (err) {
          handleCreateCampaignOnError(err);
        }
      } else invalidFields.forEach(invalidField => setFieldTouched(invalidField));
    } catch {
      isValid = false;
      notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
    }
  };

  const [remainingCampaign, setRemainingCampaign] = useState(null);
  // get sender id
  useQuery(schemas.REMAINING_CAMPAIGN, {
    variables: {
      storeId,
    },
    onCompleted: data => setRemainingCampaign(data.remainingCampaign),
    onError: () => setRemainingCampaign(null),
  });

  const isAnnouncementCampaignAndReachedMaxLimit = type => {
    const reachedMaxLimit = remainingCampaign && remainingCampaign <= 0 && type === CAMPAIGN_TYPES.ANNOUNCEMENT;
    return reachedMaxLimit;
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        ...(selectedSegment && { targetedSegment: selectedSegment, type: CAMPAIGN_TYPES.SEGMENT_TARGETING }),
      }}
      validationSchema={createValidationSchemaCreation}
      onSubmit={handleOnSubmit}
    >
      {({ values, handleChange, setFieldValue, setFieldTouched, submitForm, validateForm, errors, setErrors }) => (
        <Layout
          hasSticky
          top={
            <div>
              {isTablet && (
                <div className="pt-4">
                  <Button
                    kind="tertiaryLink"
                    size="tertiaryLink"
                    type="button"
                    icon={<CancelCampaignIcon />}
                    onClick={() => navigate(replace(paths.campaigns))}
                  />
                </div>
              )}
              <Breadcrumbs
                links={[paths.marketing, paths.campaigns]}
                path={translations.breadcrumbs.MARKETING_ADD_CAMPAIGN(values.title)}
                right={
                  <div className="flex flex-row justify-between">
                    <div className={cx('flex items-center', lang === 'ar' && 'flex-row-reverse')}>
                      <SenderId
                        title={<Text className={lang === 'ar' ? 'ml-2' : 'mr-2'} value={translations.SENDER_ID} />}
                      />
                      <span
                        className={cx(
                          'whitespace-nowrap',
                          lang === 'ar' ? 'mr-6 pr-6 border-r border-gray-300' : 'pl-6 ml-6 border-l border-gray-300',
                        )}
                      >
                        <span className="font-semibold">{selectedStore.campaignsSmsCredit}</span>
                        <Text value={translations.CREDITS_LEFT} className="inline mx-2 text-gray-700" />
                      </span>
                    </div>

                    {(values.step === 3 || values.step === 4) && !CAMPAIGN_TYPES.ANNOUNCEMENT && (
                      <Stack className="items-center" direction={lang === 'en' ? 'row' : 'row-reverse'}>
                        <Button
                          kind="secondary"
                          role="button"
                          onClick={() => handleOnCreatingCampaign(validateForm, setFieldTouched, values)}
                        >
                          <Text value={translations.SAVE} />
                        </Button>
                      </Stack>
                    )}
                  </div>
                }
              />
            </div>
          }
        >
          <Form>
            <SecondaryWizard
              isModal
              updateStep={step => {
                setFieldValue(CAMPAIGN_OBJECT_KEYS.STEP, step);
              }}
              setErrors={setErrors}
              handleCancel={() => navigate(replace(paths.campaigns))}
              customInitialAction={{
                hasCustomInitialAction: false,
                hasCustomTitle: true,
                title: translations.GET_STARTED,
                initialAction: () => {
                  // Do Nothing
                },
              }}
              customFinalAction={{
                hasCustomFinalAction:
                  calculateCreditBalanceRatio(selectedStore.campaignsSmsCredit, values.estimatedCredits) ===
                    CREDIT_BALANCE_STATE.BELOW_MINIMUM || isAnnouncementCampaignAndReachedMaxLimit(values.type),
                title: isAnnouncementCampaignAndReachedMaxLimit(values.type)
                  ? translations.SAVE_AS_DRAFT
                  : translations.PURCHASE,
                isDisabled: hasActiveConflictingCampaign,
                finalAction: () =>
                  isAnnouncementCampaignAndReachedMaxLimit(values.type)
                    ? handleOnCreatingCampaign(validateForm, setFieldTouched, values)
                    : handleAddSmsCredits(values),
              }}
              disableNextOnContactUpload={audienceType === TAB_NAMES.CONTACTS && !fileController.isFileValidated}
              disableOnCreationLoading={createCampaignLoader}
            >
              <CampaignTypeStep
                title={translations.TYPE}
                subtitle={translations.CAMPAIGN_TYPE_SUBTITLE}
                step={createCampaignWizardSteps[0]}
                key={createCampaignWizardSteps[0].name}
                validateForm={validateForm}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                setFieldTouched={setFieldTouched}
                translate={translate}
                resetValuesOnTypeChange={() => resetValuesOnTypeChange(values.type, setFieldValue)}
                remainingCampaign={remainingCampaign}
              />
              <CampaignAudienceStep
                title={translations.RECIPIENTS}
                subtitle={translations.CAMPAIGN_RECIPIENTS_SUBTITLE}
                step={createCampaignWizardSteps[1]}
                key={createCampaignWizardSteps[1].name}
                setFieldValue={setFieldValue}
                validateForm={validateForm}
                handleChange={handleChange}
                setFieldTouched={setFieldTouched}
                type={values.type}
                lookbackWindow={values.lookbackWindow}
                inactivityPeriod={values.inactivityPeriod}
                estimateReach={values.estimateReach}
                targetedSegment={values.targetedSegment}
                creditsPerMessage={values.creditsPerMessage}
                targetedSegmentError={errors.targetedSegment || errors.estimateReach}
                smsSendingLimit={values.smsSendingLimit}
                estimateReachWithSendingLimit={values.estimateReachWithSendingLimit}
                averageOrderValue={parseFloat(averageOrderValue).toFixed(3)}
                totalOrders={totalOrders}
              />
              <CampaignDetailsStep
                title={translations.DETAILS}
                subtitle={translations.CAMPAIGN_DETAILS_SUBTITLE}
                step={createCampaignWizardSteps[2]}
                key={createCampaignWizardSteps[2].name}
                setFieldValue={setFieldValue}
                validateForm={validateForm}
                handleChange={handleChange}
                setFieldTouched={setFieldTouched}
                type={values.type}
                voucherType={values.voucherType}
                minimumRequirements={values.minimumRequirements}
                voucherRedemptionLimitPerUserEnabled={values.voucherRedemptionLimitPerUserEnabled}
                sentSmsCount={values.sentSmsCount}
                estimateReach={values.estimateReach}
                estimateReachWithSendingLimit={values.estimateReachWithSendingLimit}
                creditsPerMessage={values.creditsPerMessage}
                campaignLanguage={values.campaignLanguage}
                campaignGoal={values.campaignGoal}
                targetedSegment={values.targetedSegment}
                smsBody={values.smsBody}
                averageOrderValue={!values.useContactImport && parseFloat(averageOrderValue).toFixed(3)}
                totalOrders={totalOrders}
              />
              <CampaignReviewStep
                title={translations.REVIEW}
                subtitle={translations.CAMPAIGN_REVIEW_SUBTITLE}
                step={createCampaignWizardSteps[3]}
                key={createCampaignWizardSteps[3].name}
                validateForm={validateForm}
                submitForm={submitForm}
                targetedSegment={values.targetedSegment}
                smsCredits={selectedStore.campaignsSmsCredit}
                final
                values={values}
                handleAddSmsCredits={handleAddSmsCredits}
                reachedMaxLimit={isAnnouncementCampaignAndReachedMaxLimit(values.type)}
              />
            </SecondaryWizard>
          </Form>
        </Layout>
      )}
    </Formik>
  );
};

export default CreateCampaign;
