import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import { context as userContext } from 'context/user';
import { Box, Modal } from 'components/kit';
import * as translations from 'constants/translations';
import { Text } from 'components/service';
import ActionButton from './ActionButton';
import DisablePaymentGateWay from './DisablePaymentGateWay';
import { UPDATE_PAYMENT_METHODS_ENABLED } from './schemas';

const PaymentMethodsSection = ({
  restaurantId,
  id,
  paymentCredentials,
  paymentGatewayId,
  refetch,
  title,
  description,
  isEnabled,
  icon,
  hasManualPaymentMethod,
  numOfEnabledOnlineMethods,
}) => {
  const { settings } = useContext(userContext);
  const { engageEnabled } = settings || {};
  const { restaurantPaymentMethods } = paymentCredentials || { restaurantPaymentMethods: [] };
  const enabledPaymentMethods =
    restaurantPaymentMethods && restaurantPaymentMethods.filter(obj => obj.enabled === true);
  const isPaymentEnabled = enabledPaymentMethods.length === 1 || false;

  const canDisable = isEnabled && numOfEnabledOnlineMethods < 2 && !hasManualPaymentMethod;

  const [updateEnabled, { loading: updating }] = useMutation(UPDATE_PAYMENT_METHODS_ENABLED, {
    variables: { restaurantId, id, enabled: !isEnabled },
    onCompleted: refetch,
  });

  return (
    <div className="mt-4" key={`payment_method_${id}`}>
      <Modal>
        {({ open, close }) => (
          <Box
            flexWrapClass={false}
            title={<Text className="ml-2 text-xs font-normal" value={title} />}
            note={<Text className="w-fit ml-9 text-xs text-gray-600" value={description} />}
            icon={icon}
            action={
              paymentCredentials?.paymentGateway?.id === paymentGatewayId && (
                <ActionButton
                  testId={`enable-${title[0].substring(7)}-btn`}
                  isSpinning={updating}
                  onClick={() => {
                    if (isEnabled) {
                      open({
                        body: (
                          <DisablePaymentGateWay
                            restaurantId={restaurantId}
                            id={id}
                            refetch={refetch}
                            engageEnabled={engageEnabled}
                            isPaymentEnabled={isPaymentEnabled}
                            onClose={close}
                          />
                        ),
                        size: 'max-w-xl',
                      });
                    } else {
                      updateEnabled();
                    }
                  }}
                  disabled={canDisable}
                >
                  <Text value={isEnabled ? translations.DISABLE : translations.ENABLE} />
                </ActionButton>
              )
            }
          />
        )}
      </Modal>
    </div>
  );
};

PaymentMethodsSection.propTypes = {
  restaurantId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  paymentCredentials: PropTypes.shape({
    paymentGateway: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  paymentGatewayId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  title: PropTypes.arrayOf(PropTypes.string).isRequired,
  description: PropTypes.arrayOf(PropTypes.string).isRequired,
  isEnabled: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  hasManualPaymentMethod: PropTypes.bool.isRequired,
  numOfEnabledOnlineMethods: PropTypes.number.isRequired,
};
export default PaymentMethodsSection;
