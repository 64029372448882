import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Error } from 'components/form/generic';
import EngageOptionRadio from '../EngageOptionRadio';

const EngageOptionRadioGroup = ({ setFieldValue, values, items, templateKeys, templateName, errors, translate }) => {
  const keys = Object.keys(values)[0];
  const defaultSelected = items.find(template => template.staticId === values[keys].staticId);
  const [selectedItem, setSelectedItem] = useState(defaultSelected || {});

  return (
    <>
      {items.map(item => (
        <EngageOptionRadio
          key={item.id}
          item={item}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          templateKeys={templateKeys}
          values={values}
          setFieldValue={setFieldValue}
          templateName={templateName}
          errors={errors}
          translate={translate}
        />
      ))}
      {errors[templateName]?.id && <Error>{errors[templateName]?.id}</Error>}
    </>
  );
};

EngageOptionRadioGroup.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({}),
  items: PropTypes.arrayOf(PropTypes.shape({})),
  templateKeys: PropTypes.shape({}),
  templateName: PropTypes.string,
  errors: PropTypes.shape({}),
  translate: PropTypes.func,
};

export default EngageOptionRadioGroup;
