import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const EngageSuggestedOptions = ({ optionValues, defaultValue, renderComponent, onItemClick }) => {
  const findSelectedItem = optionValues.find(item => item.value === defaultValue);
  const [otherValue, setOtherValue] = useState(!findSelectedItem && true);
  const [selectedItem, setSelectedItem] = useState(findSelectedItem || optionValues[optionValues.length - 1]);

  const handleValueClick = (e, item) => {
    e.stopPropagation();
    if (item.value) {
      setOtherValue(false);
    } else {
      setOtherValue(true);
    }
    onItemClick(item.value);
    setSelectedItem(item);
  };

  return (
    <div className="w-full">
      <div className="mt-3">
        <div className="flex items-center gap-2 mb-2">
          {optionValues.map(item => (
            <div
              key={item.id}
              className={cx(
                'flex items-center justify-center rounded py-2 px-3 bg-gray-200 text-xs',
                selectedItem.id === item.id && 'bg-primary-base text-white',
              )}
              aria-hidden
              onClick={e => handleValueClick(e, item)}
            >
              {item.text}
            </div>
          ))}
        </div>
        {otherValue && renderComponent}
      </div>
    </div>
  );
};

EngageSuggestedOptions.propTypes = {
  optionValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  defaultValue: PropTypes.number.isRequired,
  onItemClick: PropTypes.func.isRequired,
  renderComponent: PropTypes.node.isRequired,
};

export default EngageSuggestedOptions;
