export const totalEngagementsEmptyState = {
  totalEnqueuedEngageUsers: 0,
  totalSentMessages: 0,
  totalLostEngageUsers: 0,
};

export const engageTypes = {
  CASHBCK: 'cashback',
  PERCENTAGE: 'percentage',
  AMOUNT: 'amount',
};
