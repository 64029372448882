import React, { useContext, useState, useEffect } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { Button, Typography } from '@zydalabs/zac-react';

import { Field, Text } from 'components/service';
import { ReactComponent as UpgradeRocketIcon } from 'assets/upgrade-rocket.svg';
import { Formik, Form } from 'formik';
import { Label, Section } from 'components/form/generic';
import { InfoCheckbox, CheckboxAlt, Hours, PinLocation, Stepper } from 'components/form/elements';
import { context as notificationsContext } from 'context/notifications';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import * as CONSTANTS from 'constants/integrations';
import { context as featureBlockingContext } from 'context/feature-blocking';

import { ACCESS_MAP_KEYS } from 'constants/featureAccessMatrix';
import * as translations from 'constants/translations';
import { RESTAURANT_ORDER_PICKUP_TYPE, RESTAURANT_COURIER_AUTHENTICATION_TYPE } from 'constants/restaurant';
import FeatureAccess from 'common/lib/FeatureAccess';
import * as helpersData from './data';
import handleSubmissionErrors from './utils';
import BasicDetails from './BasicDetails';
import IntegrationSection from './Integrations';
import PickupTypeRadioList from './PickupTypeRadioList';
import BranchAddress from './BranchAddress';

const BranchForm = ({ initialValues, onSubmit, isSubmitting, renderBottomPane, renderTopPane, branchId }) => {
  const notifications = useContext(notificationsContext);
  const { translate, direction } = useContext(localeContext);
  const { isPosCourier, courier, selectedStore, isDeliveryCourier, pos } = useContext(userContext);
  const isAuthPerBranch = courier?.authenticationType === RESTAURANT_COURIER_AUTHENTICATION_TYPE.BRANCH_KEY;
  const isIntegrated = isPosCourier || isAuthPerBranch;

  const [integrationValidity, setIntegrationValidity] = useState(true);
  const [isValidating, setValidating] = useState(false);
  const [isPOS, setIsPOS] = useState(false);
  const [isCourier, setIsCourier] = useState(false);
  const [isOcims, setIsOcims] = useState(false);

  const { featureAccess } = useContext(featureBlockingContext);
  const featureFeatureAccess = featureAccess.get(ACCESS_MAP_KEYS.FEATURES);
  const location = useLocation();

  const {
    PICKUP: { isBlocked },
  } = featureFeatureAccess?.get(FeatureAccess.getFeaturePath(location?.pathname, selectedStore.id, branchId)) || {
    PICKUP: { isBlocked: false },
  };
  useEffect(() => {
    if (isIntegrated) {
      branchId ? setIntegrationValidity(true) : setIntegrationValidity(true);
      if (isPosCourier) setIsPOS(true);
      if (isDeliveryCourier) setIsCourier(true);
      if (pos?.courierDetails.name === CONSTANTS.OCIMS) {
        setIsOcims(true);
      }
    }
  }, [branchId, isDeliveryCourier, isPosCourier, isIntegrated, pos]);

  return (
    <div style={{ direction }}>
      <Formik
        initialValues={R.mergeDeepRight(helpersData.initialValues, initialValues || {})}
        validationSchema={helpersData.wrapperSchema({ lat: selectedStore.countryLat, lng: selectedStore.countryLng })}
        onSubmit={async data => {
          try {
            integrationValidity && !isValidating && (await onSubmit({ ...data, isPOS, isCourier, isOcims }));
          } catch (err) {
            const message = handleSubmissionErrors(err);
            notifications.show(message, 'error');
          }
        }}
      >
        {({ values, submitCount, setFieldValue, handleChange }) => (
          <Form className="pb-32 md:pb-12">
            <div className="sticky top-0 z-20 pt-1 bg-white">
              {renderTopPane && renderTopPane({ isSubmitting, isValidating })}
            </div>
            <div className="w-full md:w-2/3">
              <BasicDetails submitCount={submitCount} values={values} />
              <div className="border-b border-gray-200 pb-8">
                <Typography variant="heading16">{translate(translations.PIN_LOCATION)}</Typography>
                <Typography variant="body14" mb={24}>
                  {translate(translations.PIN_DESCRIPTION)}
                </Typography>

                <Field name="location" component={PinLocation} />
                <BranchAddress handleChange={handleChange} branchId={branchId} />
                <Field
                  name="dineIn"
                  title={<Text value={translations.PUBLIC_BRANCH_CHECKBOX} />}
                  component={CheckboxAlt}
                />
              </div>
              {values.dineIn && (
                <div className="mb-4">
                  {isBlocked ? (
                    <div style={{ direction }} className="rounded-none bg-white w-full">
                      <div className="px-4 py-4 flex flex-row">
                        <Label
                          title={<Text value={translations.PICKUP} className="font-medium text-lg flex items-center" />}
                          subtitle={
                            <Text value={translations.YOU_CAN_ALLOW_CUSTOMERS} className="text-gray-600 text-sm" />
                          }
                        />
                        <div className="pl-4 ml-auto">
                          <Button
                            size="small"
                            rounded
                            variant="tertiary"
                            startIcon={<UpgradeRocketIcon />}
                            text={translate(translations.ENABLE)}
                            onClick={() => {
                              if (window.Intercom) window.Intercom('show');
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Field
                      title={<Text value={translations.PICKUP} />}
                      description={<Text value={translations.YOU_CAN_ALLOW_CUSTOMERS} className="text-gray-600 pb-4" />}
                      name="pickupOrdersEnabled"
                      testId="pickup-enable-disable-button"
                      component={InfoCheckbox}
                      innerBody={values.pickupOrdersEnabled && <PickupTypeRadioList />}
                    />
                  )}
                </div>
              )}
              <div className="mb-4">
                <Field
                  title={<Text value={translations.DELIVERY} />}
                  description={<Text value={translations.ALLOW_YOUR_CUSTOMERS_TO} className="text-gray-600" />}
                  name="deliveryOrdersEnabled"
                  component={InfoCheckbox}
                  testId="delivery-enable-disable-button"
                />
              </div>
              <div className="mb-4">
                <Field
                  title={<Text value={translations.beachOrdering} />}
                  description={<Text value={translations.beachOrderingDescription} className="text-gray-600" />}
                  name="beachOrderEnabled"
                  component={InfoCheckbox}
                  testId="beach-order-enable-disable-button"
                />
              </div>
              <Section
                bottomOffset={4}
                disableBorder
                title={<Text value={translations.MAX_ORDER_FULFILLMENT_PERIOD} />}
                description={<Text value={translations.ORDER_FULFILLMENT_DESCRIPTION} />}
              >
                <Field name="forceScheduledThreshold" component={Stepper} min={0} />
              </Section>
              <Section
                bottomOffset={4}
                disableBorder
                title={<Text value={translations.OPENING_HOURS} />}
                description={<Text value={translations.OPENING_HOURS_DESCRIPTION} />}
              >
                <Field name="openingHours" component={Hours} testId="opening-hours" />
              </Section>

              {isIntegrated && (
                <div className="mb-4">
                  <IntegrationSection
                    branchId={branchId}
                    setIntegrationValidity={setIntegrationValidity}
                    setValidating={setValidating}
                    posBranchId={values.posBranchId || null}
                    couriers={values.couriers || []}
                    setFieldValue={setFieldValue}
                    branchName={[values.titleEn || translations.UNNAMED[0], values.titleAr || translations.UNNAMED[1]]}
                  />
                </div>
              )}
            </div>
            {renderBottomPane && renderBottomPane({ isSubmitting, isValidating })}
          </Form>
        )}
      </Formik>
    </div>
  );
};

BranchForm.propTypes = {
  initialValues: PropTypes.shape({
    titleEn: PropTypes.string.isRequired,
    titleAr: PropTypes.string.isRequired,
    addressEn: PropTypes.string.isRequired,
    addressAr: PropTypes.string.isRequired,
    forceScheduledThreshold: PropTypes.number.isRequired,
    contactNumber: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    openingHours: PropTypes.arrayOf(
      PropTypes.objectOf(
        PropTypes.shape({
          closeAt: PropTypes.string.isRequired,
          day: PropTypes.string.isRequired,
          offDay: PropTypes.bool.isRequired,
          openAt: PropTypes.string.isRequired,
        }),
      ),
    ),
    pickupOrdersEnabled: PropTypes.bool.isRequired,
    pickupOrdersType: RESTAURANT_ORDER_PICKUP_TYPE.STORE_PICKUP,
    deliveryOrdersEnabled: PropTypes.bool.isRequired,
    beachOrderEnabled: PropTypes.bool.isRequired,
    dineIn: PropTypes.bool,
    isPOS: PropTypes.bool,
    isPOSRequired: PropTypes.bool,
    posBranchId: PropTypes.string,
    courierBranchId: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  renderBottomPane: PropTypes.func.isRequired,
  renderTopPane: PropTypes.func.isRequired,
  branchId: PropTypes.string.isRequired,
};

BranchForm.defaultProps = {
  initialValues: {
    dineIn: false,
    isPOS: false,
    isPOSRequired: false,
    isCourier: false,
    posBranchId: '',
    courierBranchId: '',
  },
};

export default BranchForm;
