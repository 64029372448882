import { engageIncentives } from 'service/queries';
import { QueryEngageIncentivesArgs, EngageIncentives } from 'service/types/generated/backend_client_index';
import { GRAPHQL_CLIENT_NAMES } from 'service/constants';
import * as userService from 'services/user';
import { useQuery } from '../../utils';
import { QueryHookResponse } from '../../types/internal';

type useFetchEngageIncentivesType = (args: QueryEngageIncentivesArgs) => QueryHookResponse<EngageIncentives>;

const useFetchEngageIncentives: useFetchEngageIncentivesType = ({ subdomain }) => {
  const token = userService.getToken();
  return useQuery<EngageIncentives>({
    query: engageIncentives,
    variables: { subdomain },
    clientName: GRAPHQL_CLIENT_NAMES.BACKEND,
    requestHeaders: { Authorization: `Bearer ${token}` },
    handler: (res: any) => res,
    config: { revalidateOnMount: true },
  });
};

export default useFetchEngageIncentives;
