import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { useReplaceParams } from 'hooks/index';
import { Button, Typography } from '@zydalabs/zac-react';
import { CheckIcon } from '@zydalabs/zac-icons-react';
import { navigate } from '@reach/router';

import { context as localeContext } from 'context/locale';
import {
  ACTIVATE_WHATSAPP_AUTOPILOT,
  ACTIVATE_WHATSAPP_AUTOPILOT_DESC,
  CANCEL,
  ENABLE_ONLINE_PAYMENTS,
  ONLINE_PAYMENT_NOTE_1,
  ONLINE_PAYMENT_NOTE_2,
  ONLINE_PAYMENT_NOTE_3,
} from 'constants/translations';
import { Text } from 'components/service';
import { paymentGateways } from 'paths';
import EngageSetupNew from '../EngageSetupNew';

const ActivateModalSlide = ({ close, isOnlinePaymentAvailable, setEngageActive }) => {
  const headRef = useRef(null);
  const { translate } = useContext(localeContext);
  const replace = useReplaceParams();

  // handle activate internal helper
  const handleNavigateToPayment = () => {
    navigate(replace(paymentGateways));
  };

  return (
    <>
      {isOnlinePaymentAvailable ? (
        <EngageSetupNew close={close} setEngageActive={setEngageActive} headRef={headRef} />
      ) : (
        <>
          <div className="header p-4 border-b border-gray-200" ref={headRef}>
            <div>
              <p className="mb-1">{translate(ACTIVATE_WHATSAPP_AUTOPILOT)}</p>
              <p className="text-sm text-gray-500">{translate(ACTIVATE_WHATSAPP_AUTOPILOT_DESC)}</p>
            </div>
          </div>
          <div className="body p-4">
            <div>
              <ul className="mb-8">
                <li className="mb-2 flex items-start">
                  <CheckIcon width="16" color="#000" />
                  <Typography variant="body12">
                    <Text value={ONLINE_PAYMENT_NOTE_1} className="px-2" />
                  </Typography>
                </li>

                <li className="mb-2 flex items-start">
                  <CheckIcon width="16" color="#000" />
                  <Typography variant="body12">
                    <Text value={ONLINE_PAYMENT_NOTE_2} className="px-2" />
                  </Typography>
                </li>
                <li className="mb-2 flex items-start">
                  <CheckIcon width="16" color="#000" />
                  <Typography variant="body12">
                    <Text value={ONLINE_PAYMENT_NOTE_3} className="px-2" />
                  </Typography>
                </li>
              </ul>
              <div className="flex justify-end gap-2">
                <Button rounded variant="secondary" text={translate(CANCEL)} onClick={close} />

                <Button
                  rounded
                  variant="primary"
                  text={translate(ENABLE_ONLINE_PAYMENTS)}
                  onClick={handleNavigateToPayment}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

ActivateModalSlide.propTypes = {
  close: PropTypes.func.isRequired,
  setEngageActive: PropTypes.func.isRequired,
  isOnlinePaymentAvailable: PropTypes.bool.isRequired,
};

export default ActivateModalSlide;
