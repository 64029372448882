import React, { useContext } from 'react';
import { Button } from '@zydalabs/zac-react';

import { Stack } from 'components/kit';
import { useMutation } from '@apollo/client';
import { navigate } from '@reach/router';
import { useReplaceParams } from 'hooks/index';
import { Text, Link } from 'components/service';
import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';
import { breadcrumbs } from 'constants/translations';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { BranchForm } from 'components/common/branch';
import { Modal } from 'components/kit';
import { getBackUrl } from 'utils/history';
import * as paths from 'paths.js';
import * as schemas from './schemas';
import cx from 'classnames';
import { context as notificationsContext } from 'context/notifications';
import { COURIER_AUTHENTICATION_TYPE } from 'constants/courier';

const BranchCreation = () => {
  const replace = useReplaceParams();
  const { selectedStore, setDzmsSelectedBranch } = useContext(userContext);
  const { translate, lang } = useContext(localeContext);
  const selectedStoreId = selectedStore.id;
  const notifications = useContext(notificationsContext);

  const couriers = selectedStore.restaurantCourierSetting
    .filter(courier => courier.authenticationType === COURIER_AUTHENTICATION_TYPE.BRANCH_KEY)
    .map(courier => {
      return {
        branchExternalId: '',
        branchInternalId: courier.courierId,
        courierName: courier.courierDetails.name,
      };
    });

  const [createBranch, { loading: isCreating }] = useMutation(schemas.CREATE_BRANCH, {
    update: (cache, { data: { createBranch } }) => {
      const cacheData = cache.readQuery({
        query: schemas.BRANCHES,
        variables: { restaurantId: selectedStoreId },
      });
      cache.writeQuery({
        query: schemas.BRANCHES,
        data: {
          ...cacheData,
          branches: [...(cacheData.branches || []), createBranch],
        },
        variables: { restaurantId: selectedStoreId },
      });
    },
    onCompleted: res => {
      setDzmsSelectedBranch({ id: parseInt(res.createBranch.id) });
      navigate(
        `${replace(paths.branchCreationSuccess, {
          omitQuery: true,
        })}?location_name=${translate(res.createBranch)}`,
      );
    },
    onError: err => {
      const { body } = err.graphQLErrors[0]?.extensions?.exception || {};
      const map = {
        branch: 'Branch',
      };
      if (Object.keys(body).length) {
        for (let key of Object.keys(body)) {
          return notifications.show(`${map[key] || key} ${body[key]}`, 'error');
        }
      } else {
        return notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
      }
    },
  });

  const handleCreateBranchSubmit = async ({
    titleEn,
    titleAr,
    location,
    addressEn,
    addressAr,
    buildingNumber,
    street,
    cityId,
    block,
    deliveryNotes,
    contactNumber,
    phoneNumber,
    posBranchId,
    courierBranchId,
    dineIn,
    pickupOrdersEnabled,
    pickupOrdersType = null,
    deliveryOrdersEnabled,
    openingHours,
    forceScheduledThreshold,
  }) => {
    if (courierBranchId.length !== couriers.length) {
      notifications.show(<Text value={translations.EXTERNAL_ID_REQUIRED} />, 'error');
    } else {
      await createBranch({
        variables: {
          restaurantId: selectedStoreId,
          titleEn: titleEn,
          titleAr: titleAr,
          addressEn: addressEn,
          addressAr: addressAr,
          buildingNumber,
          street,
          cityId: parseInt(cityId),
          block,
          deliveryNotes,
          lat: location.lat,
          lng: location.lng,
          contactNumber: contactNumber,
          phoneNumber,
          posExternalId: posBranchId,
          courierExternalId: courierBranchId,
          dineIn: dineIn,
          pickupOrdersEnabled: pickupOrdersEnabled,
          pickupOrdersType: pickupOrdersEnabled ? pickupOrdersType : null,
          deliveryOrdersEnabled: deliveryOrdersEnabled,
          openingHours: openingHours,
          forceScheduledThreshold: forceScheduledThreshold,
        },
      });
    }
  };

  return (
    <Modal isMenu>
      {({ open, close }) => {
        return (
          <Layout>
            <div className={cx('pb-6 flex', lang === 'ar' && 'flex-row-reverse')}>
              <div className="w-full">
                <BranchForm
                  isSubmitting={isCreating}
                  initialValues={{
                    location: {
                      lat: +selectedStore.countryLat,
                      lng: +selectedStore.countryLng,
                    },
                    couriers: couriers.length ? couriers : [],
                    courierBranchId: [],
                  }}
                  renderTopPane={({ isSubmitting, isValidating }) => (
                    <div style={{ direction: 'ltr' }}>
                      <Breadcrumbs
                        links={[paths.setup, paths.branchList]}
                        path={breadcrumbs.SETUP_CREATE_BUSINESS_LOCATION}
                        right={
                          <div className="flex gap-2">
                            <Button
                              text={translate(translations.CANCEL)}
                              variant="tertiary"
                              rounded
                              onClick={() => navigate(replace(getBackUrl() || paths.branchList))}
                            />
                            <Button
                              type="submit"
                              text={translate(translations.CREATE)}
                              isLoading={isSubmitting || isValidating}
                              onClick={() => ({})}
                              rounded
                            />
                          </div>
                        }
                      />
                    </div>
                  )}
                  openModal={open}
                  closeModal={close}
                  onSubmit={handleCreateBranchSubmit}
                />
              </div>
            </div>
          </Layout>
        );
      }}
    </Modal>
  );
};

export default BranchCreation;
