import React, { useContext } from 'react';
import cx from 'classnames';
import { Tooltip, Tag, Typography, Spinner } from '@zydalabs/zac-react';
import { ArrowRightCircleIcon, ArrowLeftCircleIcon } from '@zydalabs/zac-icons-react';

import ProgressArrow from 'assets/progress-arrow.svg';
import ProgressArrowAr from 'assets/progress-arrow-ar.svg';
import ProgressArrowDown from 'assets/progress-arrow-down.svg';
import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import { useMobile } from 'hooks';
import {
  FIRST_TIME_ORDER_VALUE,
  ORDER_PLACED,
  RETENTION_PROGRESS,
  SECOND_TIME_ORDER_VALUE,
  THIRD_TIME_ORDER_VALUE,
  RETENTION_PROGRESS_TOOLTIP,
  SOMETHING_WENT_WRONG,
} from 'constants/translations';
import useFetchRetentionProgress from 'service/hooks/engage/useFetchRetentionProgress';

const RetentionArrow = ({ precentage, isMobile, lang }) => (
  <div
    className="flex items-center justify-center relative bg-center bg-contain bg-no-repeat"
    style={{
      backgroundImage: `url(${isMobile ? ProgressArrowDown : lang === 'ar' ? ProgressArrowAr : ProgressArrow})`,
      width: '90px',
      height: '90px',
    }}
  >
    <div style={{ marginInlineStart: isMobile ? '0' : lang === 'ar' ? '50%' : '-50%' }}>
      <Tag
        color="primary"
        inner={{
          text: `${Math.round(precentage)}%`,
          icon: lang === 'ar' ? <ArrowLeftCircleIcon /> : <ArrowRightCircleIcon />,
        }}
        size="small"
      />
    </div>
  </div>
);

const RetentionProgress = () => {
  const { lang, direction, translate } = useContext(localeContext);
  const isMobile = useMobile();
  const { selectedStore } = useContext(userContext);
  const { currency, subdomain } = selectedStore;

  const { data, isLoading, error } = useFetchRetentionProgress({ subdomain });

  // handle loading state
  if (isLoading) {
    return (
      <div className="bg-gray-100 p-4 h-full flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  // handle error state
  if (error) {
    return (
      <div className="bg-gray-100 p-4 h-full">
        <Typography variant="body14">
          <Text value={SOMETHING_WENT_WRONG} />
        </Typography>
      </div>
    );
  }

  const { engageSalesStats } = data;
  const { engagePhaseOne, engagePhaseTwo, engagePhaseThree } = engageSalesStats;
  const phase2Percentage = (engagePhaseTwo.ordersPlaced / engagePhaseOne.ordersPlaced) * 100;
  const phase3Percentage = (engagePhaseThree.ordersPlaced / engagePhaseOne.ordersPlaced) * 100;

  return (
    <div className="bg-gray-100 p-6" dir={direction}>
      <div className="mb-4">
        <div className="flex justify-start items-center" dir={direction}>
          <Typography variant="heading14">
            <Text value={RETENTION_PROGRESS} className={cx(' w-auto', lang === 'ar' ? 'ml-2' : 'mr-2')} />
          </Typography>
          <div className="flex-shrink-0">
            <Tooltip
              info={<Text className="p-2" value={RETENTION_PROGRESS_TOOLTIP} />}
              size="small"
              placement="bottom"
            />
          </div>
        </div>
      </div>
      <div className={cx('gap-7 flex flex-col md:flex-row items-center justify-between', isMobile && 'text-center')}>
        {/* Phase 1 */}
        <div className="w-full">
          <div className="mb-3">
            <Typography variant="heading20">
              <div style={{ direction }}>
                <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>
                {Math.round(engagePhaseOne.totalOrdersValue)}
              </div>
            </Typography>
            <Typography variant="heading14">
              <Text value={FIRST_TIME_ORDER_VALUE} />
            </Typography>
          </div>
          <div>
            <Typography variant="heading14">{engagePhaseOne.ordersPlaced}</Typography>
            <Typography variant="element12">
              <Text value={ORDER_PLACED} />
            </Typography>
          </div>
        </div>
        <RetentionArrow precentage={phase2Percentage || 0} isMobile={isMobile} lang={lang} />

        {/* Phase 2 */}
        <div className="w-full">
          <div className="mb-3">
            <Typography variant="heading20">
              <div style={{ direction }}>
                <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>
                {Math.round(engagePhaseTwo.totalOrdersValue)}
              </div>
            </Typography>
            <Typography variant="heading14">
              <Text value={SECOND_TIME_ORDER_VALUE} />
            </Typography>
          </div>
          <div>
            <Typography variant="heading14">{engagePhaseTwo.ordersPlaced}</Typography>
            <Typography variant="element12">
              <Text value={ORDER_PLACED} />
            </Typography>
          </div>
        </div>
        <RetentionArrow precentage={phase3Percentage || 0} isMobile={isMobile} lang={lang} />

        {/* Phase 3 */}
        <div className="w-full">
          <div className="mb-3">
            <Typography variant="heading20">
              <div style={{ direction }}>
                <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>
                {Math.round(engagePhaseThree.totalOrdersValue)}
              </div>
            </Typography>
            <Typography variant="heading14">
              <Text value={THIRD_TIME_ORDER_VALUE} />
            </Typography>
          </div>
          <div>
            <Typography variant="heading14">{engagePhaseThree.ordersPlaced}</Typography>
            <Typography variant="element12">
              <Text value={ORDER_PLACED} />
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RetentionProgress;
