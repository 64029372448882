import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import { SOMETHING_WENT_WRONG } from 'constants/translations';
import { useFetchEngageIncentives } from 'service/hooks/engage';
import Spinner from 'components/kit/Uploader/Spinner';
import EngageSteps from './EngageSteps';

const EngageSetupNew = ({ close, setEngageActive, headRef }) => {
  const { selectedStore } = useContext(userContext);
  const { translate } = useContext(localeContext);
  const { subdomain } = selectedStore;
  const { data, isLoading, error } = useFetchEngageIncentives({ subdomain });

  if (isLoading) {
    return (
      <div className="p-5 flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return <p>{translate(SOMETHING_WENT_WRONG)}</p>;
  }

  const { engageIncentives } = data;

  const transformedData = {};
  Object.keys(engageIncentives).forEach(engageKey => {
    transformedData[engageKey] = engageIncentives[engageKey].map(obj => ({
      ...obj,
      id: Number(obj.id),
      title: <Text className="text-sm" value={[obj.titleEn, obj.titleAr]} />,
      description: <Text className="text-gray-500 text-xs mt-1" value={[obj.descriptionEn, obj.descriptionAr]} />,
    }));
  });

  return (
    <EngageSteps
      close={close}
      setEngageActive={setEngageActive}
      headRef={headRef}
      transformedData={transformedData}
      subdomain={subdomain}
    />
  );
};

EngageSetupNew.propTypes = {
  close: PropTypes.func.isRequired,
  setEngageActive: PropTypes.func.isRequired,
  headRef: PropTypes.shape({}),
};

export default EngageSetupNew;
