import React, { useContext } from 'react';
import { Button } from '@zydalabs/zac-react';

import { More, Modal } from 'components/kit';
import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';
import { breadcrumbs } from 'constants/translations';
import { Breadcrumbs } from 'components/common/dashboard';
import { Text, Link } from 'components/service';
import { getBackUrl } from 'utils/history';
import * as paths from 'paths.js';
import cx from 'classnames';
import DeleteModal from './DeleteModal';

export default ({ branch, storeId, isSubmitting }) => {
  const { lang, translate } = useContext(localeContext);
  return (
    <Modal>
      {({ open, close }) => (
        <div style={{ direction: 'ltr' }}>
          <Breadcrumbs
            links={[paths.setup, paths.branchList]}
            path={breadcrumbs.SETUP_EDIT_BUSINESS_LOCATION}
            right={
              <div className="flex gap-2">
                <Link omitQuery to={getBackUrl() || paths.branchList}>
                  <Button
                    text={translate(translations.CANCEL)}
                    variant="tertiary"
                    onClick={() => ({})}
                    data-testid="cancel-location-changes"
                    rounded
                  />
                </Link>
                <Button
                  type="submit"
                  text={translate(translations.SAVE_CHANGE)}
                  isLoading={isSubmitting}
                  onClick={() => ({})}
                  data-testid="save-location-changes"
                  rounded
                />
                <More
                  items={[
                    {
                      title: <Text value={translations.DELETE} />,
                      onClick: () =>
                        open({
                          title: <Text value={branch} />,
                          body: <DeleteModal id={branch.id} storeId={storeId} onClose={close} />,
                          size: 'max-w-md',
                        }),
                    },
                  ]}
                />
              </div>
            }
          />
        </div>
      )}
    </Modal>
  );
};
