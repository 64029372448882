import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Stepper } from 'components/form/elements';
import { KWD, REWARD_AMOUNT, VOUCHER_AMOUNT, VOUCHER_PRECENTAGE } from 'constants/translations';
import { ENGAGE_TEMPLATE_IDS, suggestedCashbackAndVoucherValues, suggestedVoucherAmountValues } from '../utils';
import EngageSuggestedOptions from '../EngageSuggestedOptions';

const EngageOptionRadio = ({
  item,
  selectedItem,
  setSelectedItem,
  setFieldValue,
  values,
  templateKeys,
  templateName,
  errors,
  translate,
}) => {
  const { id, title, description, staticId } = item;
  const selected = id === selectedItem.id;

  const handleOptionClick = () => {
    setSelectedItem(item);
    if (
      staticId === ENGAGE_TEMPLATE_IDS.WELCOME ||
      staticId === ENGAGE_TEMPLATE_IDS.FREE_DELIVERY ||
      staticId === ENGAGE_TEMPLATE_IDS.MISS_YOU
    ) {
      setFieldValue(templateName, {
        ...templateKeys,
        id,
        staticId,
      });
    }
    if (staticId === ENGAGE_TEMPLATE_IDS.CASHBACK) {
      setFieldValue(templateName, {
        ...templateKeys,
        id,
        staticId,
        hasCashback: true,
        cashback: values[templateName].cashback || suggestedCashbackAndVoucherValues[1].value,
      });
    }
    if (staticId === ENGAGE_TEMPLATE_IDS.VOUCHER_PRECENTAGE) {
      setFieldValue(templateName, {
        ...templateKeys,
        id,
        staticId,
        hasVoucherPrecentage: true,
        voucherPrecentage: values[templateName].voucherPrecentage || suggestedCashbackAndVoucherValues[1].value,
      });
    }
    if (staticId === ENGAGE_TEMPLATE_IDS.VOUCHER_AMOUNT) {
      setFieldValue(templateName, {
        ...templateKeys,
        id,
        staticId,
        hasVoucherAmount: true,
        voucherAmount: values[templateName].voucherAmount || suggestedVoucherAmountValues[1].value,
      });
    }
  };

  return (
    <>
      <div
        aria-hidden
        onClick={handleOptionClick}
        className={cx(
          'p-3 border base hover:border-primary-base cursor-pointer rounded mb-2',
          selected ? 'border-primary-base bg-primary-50' : ' bg-white border-gray-100',
        )}
      >
        <div>
          {title}
          {description}
        </div>
        <>
          {staticId === ENGAGE_TEMPLATE_IDS.CASHBACK && values[templateName].hasCashback && (
            <div className="mt-2">
              <EngageSuggestedOptions
                optionValues={suggestedCashbackAndVoucherValues}
                defaultValue={values[templateName].cashback || suggestedCashbackAndVoucherValues[1].value}
                onItemClick={value => {
                  setFieldValue(templateName, {
                    ...values[templateName],
                    cashback: value,
                  });
                }}
                renderComponent={
                  <Stepper
                    error={errors[templateName]?.cashback}
                    type="text"
                    width="full"
                    left="%"
                    hideStepper
                    value={values[templateName].cashback}
                    validation={n => n >= 0}
                    placeholder={translate(REWARD_AMOUNT)}
                    onChange={e => {
                      setFieldValue(templateName, {
                        ...values[templateName],
                        cashback: Number(e.target.value),
                      });
                    }}
                    onClick={e => e.stopPropagation()}
                  />
                }
              />
            </div>
          )}
          {staticId === ENGAGE_TEMPLATE_IDS.VOUCHER_PRECENTAGE && values[templateName].hasVoucherPrecentage && (
            <div className="mt-2">
              <EngageSuggestedOptions
                optionValues={suggestedCashbackAndVoucherValues}
                defaultValue={values[templateName].voucherPrecentage || suggestedCashbackAndVoucherValues[1].value}
                onItemClick={value => {
                  setFieldValue(templateName, {
                    ...values[templateName],
                    voucherPrecentage: value,
                  });
                }}
                renderComponent={
                  <Stepper
                    error={errors[templateName]?.voucherPrecentage}
                    type="text"
                    width="full"
                    left="%"
                    hideStepper
                    value={values[templateName].voucherPrecentage}
                    validation={n => n >= 0}
                    placeholder={translate(VOUCHER_PRECENTAGE)}
                    onChange={e => {
                      setFieldValue(templateName, {
                        ...values[templateName],
                        voucherPrecentage: e.target.value,
                      });
                    }}
                  />
                }
              />
            </div>
          )}
          {staticId === ENGAGE_TEMPLATE_IDS.VOUCHER_AMOUNT && values[templateName].hasVoucherAmount && (
            <div className="w-full mt-2">
              <EngageSuggestedOptions
                optionValues={suggestedVoucherAmountValues}
                defaultValue={values[templateName].voucherAmount || suggestedVoucherAmountValues[1].value}
                onItemClick={value => {
                  setFieldValue(templateName, {
                    ...values[templateName],
                    voucherAmount: value,
                  });
                }}
                renderComponent={
                  <Stepper
                    error={errors[templateName]?.voucherAmount}
                    type="text"
                    width="full"
                    left={translate(KWD)}
                    hideStepper
                    value={values[templateName].voucherAmount}
                    validation={n => n >= 0}
                    placeholder={translate(VOUCHER_AMOUNT)}
                    onChange={e => {
                      setFieldValue(templateName, {
                        ...values[templateName],
                        voucherAmount: e.target.value,
                      });
                    }}
                  />
                }
              />
            </div>
          )}
        </>
      </div>
    </>
  );
};

EngageOptionRadio.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    staticId: PropTypes.number,
    title: PropTypes.node,
    description: PropTypes.node,
  }),
  selectedItem: PropTypes.shape({
    id: PropTypes.number,
  }),
  setSelectedItem: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({}),
  templateKeys: PropTypes.shape({}),
  templateName: PropTypes.string,
  errors: PropTypes.shape({}),
  translate: PropTypes.func,
};

export default EngageOptionRadio;
