import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Typography, useTheme, TextInput } from '@zydalabs/zac-react';
import 'style.css';

import { Field } from 'components/service';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';

const BasicDetails = () => {
  const { translate } = useContext(localeContext);
  const { colors } = useTheme();

  return (
    <div className="mb-9 mt-6 pb-9 border-b border-gray-200">
      <Typography variant="heading16">{translate(translations.BASIC_DETAILS)}</Typography>
      <Typography variant="body14" color={colors.gray[400]} mb={24}>
        {translate(translations.BASIC_DETAILS_DESC)}
      </Typography>
      <div className="flex flex-col md:flex-row justify-between items-start gap-4 mb-6">
        <div className="w-full mb-6 md:mb-0 text-input">
          <Field
            type="text"
            name="titleEn"
            placeholder={translations.ENTER_BUSINESS_LOCATION_NAME[0]}
            component={TextInput}
            label={`${translate(translations.BRANCH_NAME)} (${translate(translations.ENGLISH)})`}
            data-testid="business-location-name-en"
          />
        </div>
        <div className="w-full mb-0 md:mb-0 text-input">
          <Field
            type="text"
            direction="rtl"
            name="titleAr"
            placeholder={translations.ENTER_BUSINESS_LOCATION_NAME[1]}
            component={TextInput}
            label={`${translate(translations.BRANCH_NAME)} (${translate(translations.ARABIC)})`}
            data-testid="business-location-name-ar"
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-start gap-4 mb-6">
        <div className="w-full mb-6 md:mb-0 text-input">
          <Field
            type="text"
            name="contactNumber"
            placeholder={translate(translations.CUSTOMER_SERVICE_NUMBER)}
            label={translate(translations.CUSTOMER_SERVICE_NUMBER)}
            component={TextInput}
          />
        </div>
        <div className="w-full mb-0 md:mb-0 text-input">
          <Field
            type="text"
            name="phoneNumber"
            placeholder={translate(translations.BRANCH_PHONE_NUMBER)}
            label={translate(translations.BRANCH_PHONE_NUMBER)}
            component={TextInput}
          />
        </div>
      </div>
    </div>
  );
};

BasicDetails.propTypes = {
  values: PropTypes.shape({
    addressAr: PropTypes.string.isRequired,
    addressEn: PropTypes.string.isRequired,
    contactNumber: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    deliveryOrdersEnabled: PropTypes.bool.isRequired,
    dineIn: PropTypes.bool.isRequired,
    externalId: PropTypes.string.isRequired,
    forceScheduledThreshold: PropTypes.number.isRequired,
    isPOS: PropTypes.bool.isRequired,
    location: PropTypes.objectOf(
      PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
      }),
    ),
    openingHours: PropTypes.arrayOf(
      PropTypes.objectOf(
        PropTypes.shape({
          closeAt: PropTypes.string.isRequired,
          day: PropTypes.string.isRequired,
          offDay: PropTypes.bool.isRequired,
          openAt: PropTypes.string.isRequired,
        }),
      ),
    ),
    pickupOrdersEnabled: PropTypes.bool.isRequired,
    pickupOrdersType: PropTypes.string.isRequired,
    titleAr: PropTypes.string.isRequired,
    titleEn: PropTypes.string.isRequired,
  }).isRequired,
};

export default BasicDetails;
