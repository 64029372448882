import React from 'react';
import cx from 'classnames';

const MoreContent = ({ top, left, items, lang, setOpened }) => {
  return (
    <div
    className={cx(
      'z-10 w-32 shadow bg-white min-w-max',
    )}
    style={{
      position: "absolute",
      top: `${top}px`,
      left: `${left}px`,
      minWidth: '200px'
    }}

  >
    {items.map((item, i) =>
      item.type === 'separator' ? (
        <div className="h-px w-full bg-zyda-grey-50 my-2" />
      ) : (
        <button
          key={i}
          onClick={() => {
            setOpened(false);
            !item.disabled && item.onClick();
          }}
          className={cx(
            'block w-full py-2 px-4 border-t border-b border-transparent',
            'hover:bg-primary-50 cursor-pointer text-xs outline-none focus:outline-none',
            lang === 'en' ? 'text-left' : 'text-right',
            item.disabled && 'opacity-25 cursor-not-allowed',
          )}
          data-testid="disable-stockManagement"
          disabled={item.disabled}
        >
          {item.title}
        </button>
      ),
    )}
  </div>
  )
}

export default MoreContent;