import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@zydalabs/zac-react';
import { PlayIcon, XIcon, ChevronsRightIcon } from '@zydalabs/zac-icons-react';
import cx from 'classnames';

import { context as localeContext } from 'context/locale';
import {
  DEACTIVATE_ENGAGE_MESSAGE,
  WATCH_VIDEO,
  PROCEED_TO_DEACTIVATE,
  DEACTIVATE_ENGAGE_CONFIRM_TITLE,
} from 'constants/translations';
import { Text } from 'components/service';

const DeactivateModal = ({ open, close }) => {
  const { translate, direction } = useContext(localeContext);
  const [playVideo, setPlayVideo] = useState(false);

  return (
    <div className="flex flex-col" style={{ direction }}>
      <div className="relative">
        <div className="z-10">
          {playVideo ? (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video controls className="h-full" autoPlay>
              <source src="https://s3.eu-west-1.amazonaws.com/static.zyda.com/engage.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <div
              className="h-56"
              alt="engage"
              style={{
                backgroundImage:
                  'url(https://s3.eu-west-1.amazonaws.com/static.zyda.com/Screenshot+2023-08-15+at+3.13.24+AM.png)',
                backgroundSize: 'cover', // Maintain the aspect ratio and cover the container
              }}
            >
              <div className="absolute inset-0 flex justify-center items-center">
                <Button
                  rounded
                  size="medium"
                  text={translate(WATCH_VIDEO)}
                  onClick={() => setPlayVideo(true)}
                  startIcon={<PlayIcon width="20px" color="white" />}
                />
              </div>
            </div>
          )}
        </div>
        <div className="absolute top-4 right-4 cursor-pointer z-20">
          <Button
            rounded
            size="small"
            variant="secondary"
            onClick={close}
            endIcon={<XIcon width="32px" color="#c1c1c1" />}
          />
        </div>
      </div>
      <div className="mx-6 flex gap-2 flex-col mt-4">
        <Typography variant="heading20">
          <Text value={DEACTIVATE_ENGAGE_CONFIRM_TITLE} />
        </Typography>
        <Typography variant="body14">
          <Text value={DEACTIVATE_ENGAGE_MESSAGE} />
        </Typography>
        <div className="self-center flex gap-2 cursor-pointer mt-4 items-center opacity-60" aria-hidden onClick={open}>
          <Typography variant="body14" color="#1F1F1F" cursor="pointer">
            <Text value={PROCEED_TO_DEACTIVATE} />
          </Typography>
          <div className={cx(direction === 'rtl' ? 'rotate-180' : '')}>
            <ChevronsRightIcon width="18" color="black" />
          </div>
        </div>
      </div>
    </div>
  );
};

DeactivateModal.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.func.isRequired,
};

export default DeactivateModal;
