import React, { useContext, useState, useRef, useEffect } from 'react';
import { context as localeContext } from 'context/locale';
import cx from 'classnames';
import { Button } from 'components/kit';
import { Text } from 'components/service';
import ReactDOM from "react-dom";
import MoreContent from './MoreContent';

const More = ({
  items,
  kind = 'secondary',
  size = 'baseIcon',
  iconSize,
  iconColor,
  testIdBtn,
  className,
  disabled,
  modalUp,
  moreButtonText,
  stopEventPropagation = false,
  icon = 'more_vert',
}) => {
  const { lang } = useContext(localeContext);
  const [isOpened, setOpened] = useState(false);
  const [contentPosition, setContentPosition] = useState({ top: 0, left: 0 });

  const ref = useRef();
  const contentRef = useRef();

  useEffect(() => {
    const handleClickOutsideForMoreContent = event => {
      if (
        ref.current && !ref.current.contains(event.target) &&
        contentRef.current && !contentRef.current.contains(event.target))
      {
        setOpened(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutsideForMoreContent);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideForMoreContent);
    };
  }, []);


  return (
    <div className="relative" ref={ref}>
      <Button
        onClick={e => {
          const buttonRect = ref.current.getBoundingClientRect();
          setContentPosition({
            top: buttonRect.top + buttonRect.height + window.scrollY,
            left: lang === 'en' ? (buttonRect.left + buttonRect.width) - 200 : (buttonRect.left),
          });
          stopEventPropagation && e.stopPropagation();
          !disabled && setOpened(!isOpened);
        }}
        type="button"
        kind={kind}
        icon={icon}
        size={size}
        className={className}
        iconSize={iconSize}
        iconColor={iconColor}
        data-testid={testIdBtn}
        disabled={disabled}
        {...(moreButtonText && { isChevron: true })}
      >
        {moreButtonText && <Text className={cx(lang === 'en' ? 'px-2' : 'px-0')} value={moreButtonText} />}
      </Button>
      {isOpened && (
        ReactDOM.createPortal(
        <div ref={contentRef}>
          <MoreContent top={contentPosition.top} left={contentPosition.left} items={items} lang={lang} setOpened={setOpened} />
        </div>,
        document.getElementById('modal-root'),
        )
        
      )}
    </div>
  );
};

export default More;
