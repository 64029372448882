import { apiMutate } from 'service/utils/mutation';

import * as userService from 'services/user';
import { GRAPHQL_CLIENT_NAMES } from 'service/constants';
import { createEngageSettingMutation } from 'service/mutations/engage';
import {
  ToggleEngageSettingsPayload,
  MutationToggleEngageSettingsArgs,
} from 'service/types/generated/backend_client_index';

type useCreateEngageSettingType = () => ({
  settings,
}: {
  settings?: MutationToggleEngageSettingsArgs;
}) => Promise<void | Partial<ToggleEngageSettingsPayload> | { hasError: boolean }>;

const useCreateEngageSetting: useCreateEngageSettingType = () => {
  const token = userService.getToken();
  return settings =>
    apiMutate({
      mutation: createEngageSettingMutation,
      variables: { ...settings },
      clientName: GRAPHQL_CLIENT_NAMES.BACKEND,
      requestHeaders: { Authorization: `Bearer ${token}` },
      handler: (res: any) => res,
    });
};

export default useCreateEngageSetting;
