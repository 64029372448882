import React, { useContext, useState } from 'react';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { useLocation } from '@reach/router';
import cx from 'classnames';

import GoogleTagManagerIcon from 'assets/google-tag-manager.svg';
import GoogleAnalyticsIcon from 'assets/google-analytics-image.svg';
import FacebookPixelIcon from 'assets/facebook-pixel-image.svg';
import SnapchatIcon from 'assets/snapchat.png';
import TiktokIcon from 'assets/tiktok.svg';
import TrackBarIcon from 'assets/trackbar.svg';
import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { context as notificationsContext } from 'context/notifications';
import { context as featureBlockingContext } from 'context/feature-blocking';
import { Field, Text } from 'components/service';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import {
  breadcrumbs,
  GOOGLE,
  GOOGLE_ANALYTICS,
  GOOGLE_ANALYTICS_DESCRIPTION,
  GOOGLE_TRACKING_ID,
  GTM_TITLE,
  GTM_DESCRIPTION,
  GTM_TRACKING_ID,
  FACEBOOK,
  FACEBOOK_PIXEL,
  FACEBOOK_PIXEL_DESCRIPTION,
  FACEBOOK_TRACKING_ID,
  OTHER,
  SNAPCHAT_TRACKING_ID,
  SNAPCHAT_PIXEL,
  SNAPCHAT_PIXEL_DESCRIPTION,
  PASTE_YOUR_CODE_HERE,
  CONNECT,
  CONNECTED,
  SUCCESS,
  GOOGLE_ANALYTICS_NOTE,
  GOOGLE_TRACKING_DRIVE_LINK,
  GET_WITH_GROWTH,
  GET_WITH_STANDARD,
  SNAPCHAT_DESCRIPTION,
  TIKTOK_DESCRIPTION,
  ANALYTICS_DESCRIPTION,
  FACEBOOK_CAPI,
  FACEBOOK_CAPI_DESCRIPTION,
  NEW,
  CAPI_DISABLED_NOTE,
  NO_LONGER_SUPPORTED,
} from 'constants/translations';
import { SOCIAL_MEDIA_LINKS } from 'constants/helperLinks';
import { InputTypes, TrackingIntegrations } from 'constants/marketing';
import Slideover from 'components/common/dashboard/Layout/Slideover/Slideover';
import { Button } from 'components/kit';
import { Input, Textarea } from 'components/form/elements';
import { isStoreSFV4 } from 'common/utils/isStoreSfv4';
import { useTablet } from 'hooks';
import FeatureAccess from 'common/lib/FeatureAccess';
import { ACCESS_MAP_KEYS } from 'constants/featureAccessMatrix';
import FacebookPartnerConnect from './FacebookPartnerConnect';
import IntegrationsConnectionTemplate from './IntegrationsConnectionTemplate';
import TrackingIdentifiersSlideoverTemplate from './TrackingIdentifiersSlideoverTemplate';
import trackingAndSocialMediaValidationSchema from './data';
import UPDATE_RESTAURANT from './schemas';
import FacebookActionCard from './FacebookPartnerConnect/FacebookActionCard';

const TrackingAndSocialMedia = () => {
  sessionStorage.removeItem('facebook_business_id');
  const { selectedStore, showSlideOver, setSlideOver, setSelectedStore } = useContext(userContext);
  const {
    id: selectedStoreId,
    fbadTrackId,
    gtmTrackId,
    gaTrackId,
    snapTrackId,
    deploymentLocation,
    capiAccessToken,
  } = selectedStore;
  const { lang, direction, translate } = useContext(localeContext);
  const { featureAccess } = useContext(featureBlockingContext);
  const notifications = useContext(notificationsContext);
  const isTablet = useTablet();
  const location = useLocation();
  const featureAccessObject = featureAccess
    .get(ACCESS_MAP_KEYS.FEATURES)
    ?.get(FeatureAccess.getFeaturePath(location?.pathname, selectedStoreId));

  const [updateRestaurant, { loading }] = useMutation(UPDATE_RESTAURANT, {
    variables: { id: selectedStoreId },
    onError: err => {
      const body = err?.graphQLErrors[0]?.extensions?.exception?.body;
      Object.keys(body).forEach(key => notifications.show(`${key} ${body[key][0]}`, 'error'));
    },
    onCompleted: res => {
      const { id, ...trackers } = res?.updateRestaurant;
      setSelectedStore({ ...selectedStore, ...trackers });
      notifications.show(<Text value={SUCCESS} />, 'success');
    },
  });

  const [selectedIntegration, setSelectedIntegration] = useState();

  const handleActionButtonClick = (selectedIntegrationOption, isConnected) => {
    if (!isConnected) {
      setSlideOver(true);
      setSelectedIntegration(selectedIntegrationOption);
    } else
      updateRestaurant({
        variables: {
          [TrackingIntegrations[selectedIntegrationOption].trackerName]: '',
        },
      });
  };

  return (
    <Layout top={<Breadcrumbs helperLinks={SOCIAL_MEDIA_LINKS} path={breadcrumbs.SOCIAL_MEDIA_TRACKING} />}>
      <div dir={direction}>
        {selectedIntegration && (
          <Formik
            initialValues={{
              gaTrackId: !gaTrackId ? '' : gaTrackId,
              fbadTrackId: !fbadTrackId ? '' : fbadTrackId,
              snapTrackId: !snapTrackId ? '' : snapTrackId,
              gtmTrackId: !gtmTrackId ? '' : gtmTrackId,
              capiAccessToken: !capiAccessToken ? '' : capiAccessToken,
            }}
            validationSchema={trackingAndSocialMediaValidationSchema}
          >
            {({ errors, values }) => (
              <Slideover
                isOpen={showSlideOver}
                setSlideOver={setSlideOver}
                title={<Text value={OTHER} />}
                body={
                  <TrackingIdentifiersSlideoverTemplate
                    imageSrc={TrackingIntegrations[selectedIntegration].imageSrc}
                    imageAlt={translate(TrackingIntegrations[selectedIntegration].title)}
                    title={TrackingIntegrations[selectedIntegration].title}
                    subtitle={TrackingIntegrations[selectedIntegration].subtitle}
                    input={
                      <Field
                        type="text"
                        name={TrackingIntegrations[selectedIntegration].trackerName}
                        placeholder={translate(PASTE_YOUR_CODE_HERE)}
                        component={
                          TrackingIntegrations[selectedIntegration].inputType === InputTypes.textareaInput
                            ? Textarea
                            : Input
                        }
                        rows="5"
                      />
                    }
                    inputSubtitle={TrackingIntegrations[selectedIntegration].inputSubtitle}
                    action={
                      <Button
                        size="xs"
                        isSpinning={loading}
                        disabled={
                          errors[TrackingIntegrations[selectedIntegration].trackerName] ||
                          !values[TrackingIntegrations[selectedIntegration].trackerName]
                        }
                        onClick={() => {
                          setSlideOver(false);
                          if (
                            values[TrackingIntegrations[selectedIntegration].trackerName] &&
                            !errors[TrackingIntegrations[selectedIntegration].trackerName]
                          )
                            updateRestaurant({
                              variables: {
                                [TrackingIntegrations[selectedIntegration].trackerName]: values[
                                  TrackingIntegrations[selectedIntegration].trackerName
                                ].trim(),
                              },
                            });
                        }}
                      >
                        <Text value={CONNECT} />
                      </Button>
                    }
                    setShowSlideover={setSlideOver}
                    direction={direction}
                  />
                }
                subdomain={selectedStore.fullUrl}
              />
            )}
          </Formik>
        )}
        <div className="pb-2 w-full xl:w-2/3">
          <Text className="font-semibold text-sm text-gray-500 mb-4" value={FACEBOOK} />
          <FacebookPartnerConnect />
          <div className="border rounded-lg w-full mt-4">
            <IntegrationsConnectionTemplate
              imageSrc={FacebookPixelIcon}
              imageAlt={lang === 'en' ? FACEBOOK_PIXEL[0] : FACEBOOK_PIXEL[1]}
              title={FACEBOOK_PIXEL}
              subtitle={FACEBOOK_PIXEL_DESCRIPTION}
              direction={direction}
              isConnected={!!fbadTrackId}
              upgradeable={featureAccessObject?.FACEBOOK_PIXEL?.isBlocked}
              upgradeText={GET_WITH_STANDARD}
              translate={translate}
              badge={{
                hasBadge: !!fbadTrackId,
                title: CONNECTED,
                color: 'bg-green-600',
              }}
              actionButtonSection={{
                hasButton: true,
                onClick: () => handleActionButtonClick(TrackingIntegrations.FACEBOOK_PIXEL.id, !!fbadTrackId),
                buttonDisabled: false,
              }}
              learnMoreUrl="https://www.facebook.com/business/learn/facebook-ads-pixel"
              additionalInformationSection={{ title: FACEBOOK_TRACKING_ID, value: fbadTrackId }}
              isTablet={isTablet}
            />
          </div>
          <div className="border rounded-lg w-full mt-4">
            <IntegrationsConnectionTemplate
              imageSrc={FacebookPixelIcon}
              imageAlt={lang === 'en' ? FACEBOOK_CAPI[0] : FACEBOOK_CAPI[1]}
              title={FACEBOOK_CAPI}
              subtitle={FACEBOOK_CAPI_DESCRIPTION}
              direction={direction}
              isConnected={!!capiAccessToken && fbadTrackId}
              upgradeable={featureAccessObject?.GTM?.isBlocked}
              upgradeText={GET_WITH_GROWTH}
              translate={translate}
              badge={{
                hasBadge: true,
                title: capiAccessToken && fbadTrackId ? CONNECTED : NEW,
                color: capiAccessToken && fbadTrackId ? 'bg-green-600' : 'bg-primary-base',
              }}
              actionButtonSection={{
                hasButton: true,
                onClick: () => handleActionButtonClick(TrackingIntegrations.FACEBOOK_CAPI.id, !!capiAccessToken),
                buttonDisabled: !fbadTrackId,
              }}
              learnMoreUrl="https://www.facebook.com/business/help/2041148702652965?id=818859032317965"
              isTablet={isTablet}
              isCapi
              capiBlockedText={!fbadTrackId && CAPI_DISABLED_NOTE}
            />
          </div>
        </div>
        <div className="w-full xl:w-2/3">
          <Text className="font-semibold text-sm text-gray-500 mt-6 mb-4" value={GOOGLE} />

          <div className="border rounded-lg w-full mb-4">
            <IntegrationsConnectionTemplate
              imageSrc={GoogleTagManagerIcon}
              imageAlt={GTM_TITLE[lang === 'en' ? 0 : 1]}
              title={GTM_TITLE}
              subtitle={GTM_DESCRIPTION}
              extraInfo={GOOGLE_TRACKING_DRIVE_LINK}
              direction={direction}
              isConnected={!!gtmTrackId}
              upgradeable={featureAccessObject?.GTM?.isBlocked}
              upgradeText={GET_WITH_GROWTH}
              translate={translate}
              badge={{
                hasBadge: !!gtmTrackId,
                title: CONNECTED,
                color: 'bg-green-600',
              }}
              actionButtonSection={{
                hasButton: true,
                onClick: () => handleActionButtonClick(TrackingIntegrations.GOOGLE_TAG_MANAGER.id, !!gtmTrackId),
                buttonDisabled: false,
              }}
              additionalInformationSection={{ title: GTM_TRACKING_ID, value: gtmTrackId }}
              isTablet={isTablet}
            />
            <hr className="self-center mx-4 bg-gray-400" />
            <div className="mt-4 px-6">
              <div className={cx('flex', isTablet ? 'flex-col' : 'justify-between')}>
                <FacebookActionCard
                  description={SNAPCHAT_DESCRIPTION}
                  icon={<img src={SnapchatIcon} width="24" alt="Snapchat" />}
                  direction={direction}
                />
                <FacebookActionCard
                  description={TIKTOK_DESCRIPTION}
                  icon={<img src={TiktokIcon} width="24" alt="Tiktok" />}
                  direction={direction}
                />
                <FacebookActionCard
                  description={ANALYTICS_DESCRIPTION}
                  icon={<img src={TrackBarIcon} width="24" alt="Analytics" />}
                  direction={direction}
                />
              </div>
            </div>
          </div>

          <div className="border rounded-lg w-full">
            <IntegrationsConnectionTemplate
              imageSrc={GoogleAnalyticsIcon}
              imageAlt={lang === 'en' ? GOOGLE_ANALYTICS[0] : GOOGLE_ANALYTICS[1]}
              title={GOOGLE_ANALYTICS}
              subtitle={GOOGLE_ANALYTICS_DESCRIPTION}
              direction={direction}
              isConnected={!!gaTrackId}
              badge={{
                hasBadge: true,
                title: NO_LONGER_SUPPORTED,
                color: 'bg-red-600',
              }}
              actionButtonSection={{
                hasButton: false,
                onClick: () => handleActionButtonClick(TrackingIntegrations.UNIVERSAL_ANALYTICS.id, !!gaTrackId),
                buttonDisabled: false,
              }}
              additionalInformationSection={{ title: GOOGLE_TRACKING_ID, value: gaTrackId }}
              isTablet={isTablet}
              tooltip={{ hasTooltip: true, title: GOOGLE_ANALYTICS_NOTE }}
            />
          </div>
        </div>
        {!isStoreSFV4(deploymentLocation) && (
          <div className="mt-4 mb-4 w-full xl:w-2/3">
            <Text className="font-semibold text-sm text-gray-500 mt-6 mb-4" value={OTHER} />
            <div className="border rounded-lg w-full">
              <IntegrationsConnectionTemplate
                imageSrc={SnapchatIcon}
                imageAlt={lang === 'en' ? SNAPCHAT_PIXEL[0] : SNAPCHAT_PIXEL[1]}
                title={SNAPCHAT_PIXEL}
                subtitle={SNAPCHAT_PIXEL_DESCRIPTION}
                direction={direction}
                isConnected={!!snapTrackId}
                badge={{
                  hasBadge: !!snapTrackId,
                  title: CONNECTED,
                  color: 'bg-green-600',
                }}
                actionButtonSection={{
                  hasButton: true,
                  onClick: () => handleActionButtonClick(TrackingIntegrations.SNAPCHAT_PIXEL.id, !!snapTrackId),
                  buttonDisabled: false,
                }}
                learnMoreUrl="https://businesshelp.snapchat.com/en-US/article/snap-pixel-about"
                additionalInformationSection={{ title: SNAPCHAT_TRACKING_ID, value: snapTrackId }}
                isTablet={isTablet}
              />
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};
export default TrackingAndSocialMedia;
