import React, { useState, useContext } from 'react';
import { Button, Typography } from '@zydalabs/zac-react';
import { CheckCircleIcon, BullhornIcon } from '@zydalabs/zac-icons-react';

import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import {
  ACTIVATE,
  breadcrumbs,
  DEACTIVATE,
  ENGAGE_LOW_ORDER_NOTE,
  SOMETHING_WENT_WRONG,
  ENGAGE_LOW_ORDER_NOTE_DESC,
  ARE_YOU_SURE,
  CURRENT_SETTING_TITLE,
} from 'constants/translations';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { Text } from 'components/service';
import { COUNTRY_CODES_ENUM } from 'utils/enums';
import Popup from 'components/kit/Popup';
import Slideover from 'components/common/dashboard/Layout/Slideover/Slideover';
import { ENGAGE_AUTO_PILOT } from 'constants/helperLinks';
import EngageWizard from './EngageWizard';
import ConversationCount from './ConversationCount';
import GeneratedSales from './GeneratedSales';
import RetentionProgress from './RetentionProgress';
import DeactivateModal from './modals/DeactivateModal';
import EngageInfoList from './EngageInfoList';
import usePaymentCredentials from './hooks/usePaymentCredentials';
import DeactivateConfirmModal from './modals/DeactivateConfirmModal';
import ActivateModalSlide from './modals/ActivateModalSlide';
import CurrentSettingModal from './modals/CurrentSettingModal';

const Engage = () => {
  const { settings, selectedStore, hasRole } = useContext(userContext);
  const { direction, translate } = useContext(localeContext);
  const { engageEnabled } = settings || {};
  const { countryCode } = selectedStore;
  const { isOnlinePaymentAvailable } = usePaymentCredentials();

  const [showActivateSlide, setShowActivateSlide] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showDeactivateConfirmModal, setShowDeactivateConfirmModal] = useState(false);
  const [showCurrentSetting, setShowCurrentSetting] = useState(false);
  const [totalOrders, setTotalOrders] = useState(0);
  const [engageActive, setEngageActive] = useState(engageEnabled);

  if ((!hasRole('owner') && !hasRole('ops_manager')) || countryCode !== COUNTRY_CODES_ENUM.KUWAIT) {
    return (
      <Layout top={<Breadcrumbs path={breadcrumbs.MARKETING_AUTO_PILOT} />}>
        <Typography variant="body16">{translate(SOMETHING_WENT_WRONG)}</Typography>
      </Layout>
    );
  }

  return (
    <Layout
      top={
        <Breadcrumbs
          helperLinks={ENGAGE_AUTO_PILOT}
          path={breadcrumbs.MARKETING_AUTO_PILOT}
          right={
            <>
              {engageActive && (
                <div className="mx-2">
                  <Button
                    rounded
                    variant="tertiary"
                    startIcon={<BullhornIcon width="20px" color="#196eff" />}
                    onClick={() => setShowCurrentSetting(true)}
                  />
                </div>
              )}

              <Button
                rounded
                variant={engageActive ? 'secondary' : 'primary'}
                text={translate(engageActive ? DEACTIVATE : ACTIVATE)}
                onClick={() => (engageActive ? setShowDeactivateModal(true) : setShowActivateSlide(true))}
              />

              {showDeactivateModal && (
                <Popup preventClosure size="max-w-xl" close={() => setShowDeactivateModal(false)}>
                  <DeactivateModal
                    open={() => {
                      setShowDeactivateModal(false);
                      setShowDeactivateConfirmModal(true);
                    }}
                    close={() => setShowDeactivateModal(false)}
                  />
                </Popup>
              )}
              {showDeactivateConfirmModal && (
                <Popup heading={ARE_YOU_SURE} size="max-w-xl" close={() => setShowDeactivateConfirmModal(false)}>
                  <DeactivateConfirmModal
                    close={() => setShowDeactivateConfirmModal(false)}
                    setEngageActive={setEngageActive}
                  />
                </Popup>
              )}

              {showCurrentSetting && (
                <Popup
                  position="top"
                  heading={CURRENT_SETTING_TITLE}
                  size="max-w-2xl"
                  close={() => setShowCurrentSetting(false)}
                >
                  <CurrentSettingModal />
                </Popup>
              )}

              <Slideover
                isOpen={showActivateSlide}
                setSlideOver={() => setShowActivateSlide(false)}
                body={
                  <ActivateModalSlide
                    close={() => setShowActivateSlide(false)}
                    isOnlinePaymentAvailable={isOnlinePaymentAvailable}
                    setEngageActive={setEngageActive}
                  />
                }
              />
            </>
          }
        />
      }
    >
      <>
        <div className="relative">
          {engageActive ? (
            <>
              <div
                className="p-4 mt-2 mb-4 border border-zyda-green-150 bg-zyda-green-50 flex items-start"
                style={{ direction }}
              >
                <div className="flex-shrink-0">
                  <CheckCircleIcon color="#37995D" width="18px" />
                </div>

                <div className="mx-2">
                  <Typography variant="heading14" mb={4}>
                    <Text value={ENGAGE_LOW_ORDER_NOTE} />
                  </Typography>
                  <Typography variant="body14">
                    <Text value={ENGAGE_LOW_ORDER_NOTE_DESC} />
                  </Typography>
                </div>
              </div>

              <div className="md:flex justify-between items-center p-4 bg-gray-100 gap-7 mb-5">
                <div className="mb-5 md:mb-0 md:w-3/5">
                  <GeneratedSales totalOrders={totalOrders} />
                </div>

                <div className="md:w-2/5">
                  <ConversationCount setTotalOrders={setTotalOrders} />
                </div>
              </div>
              <div className="mb-5">
                <RetentionProgress />
              </div>
            </>
          ) : (
            <EngageInfoList />
          )}
        </div>
        <div>
          <EngageWizard engageActive={engageActive} open={() => setShowActivateSlide(true)} />
        </div>
      </>
    </Layout>
  );
};

export default Engage;
