import React, { useContext } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Typography } from '@zydalabs/zac-react';
import { XIcon } from '@zydalabs/zac-icons-react';

import { context as localeContext } from 'context/locale';
import { useMobile } from 'hooks';

const Popup = ({
  size = 'max-w-md',
  position = 'center',
  heading,
  subHeading,
  close,
  preventClosure,
  enableOutsideClick,
  children,
}) => {
  const isMobile = useMobile();
  const { translate, direction } = useContext(localeContext);
  const positionObj = {
    center: 'items-center',
    start: 'items-start',
  };
  const containerStyle = { backgroundColor: 'rgba(0, 0, 0, 0.6)' };
  const handlePopupClose = () => {
    if (!preventClosure || enableOutsideClick) {
      close();
    }
  };

  return createPortal(
    <div className="fixed top-0 left-0 w-full h-full z-50">
      <div
        aria-hidden
        onClick={handlePopupClose}
        className={cx('w-full h-full flex justify-center overflow-y-auto p-3', isMobile ? 'items-start' : positionObj[position])}
        style={containerStyle}
      >
        <div
          aria-hidden
          onClick={e => e.stopPropagation()}
          className={cx('bg-white relative w-full h-fit', isMobile ? 'max-w-full' : size)}
          dir={direction}
        >
          {!preventClosure && (
            <div className="flex items-start justify-between border-b border-zyda-grey-50 p-4">
              <div>
                <Typography variant="heading16">{translate(heading)}</Typography>
                {subHeading && (
                  <Typography variant="body14" color="rgba(31, 31, 31, 0.65)" mt={4}>
                    {translate(subHeading)}
                  </Typography>
                )}
              </div>
              <div className="flex items-center justify-center cursor-pointer">
                <XIcon width="24" color="#000" onClick={handlePopupClose} />
              </div>
            </div>
          )}

          <div className="p-4">{children}</div>
        </div>
      </div>
    </div>,
    document.getElementById('modal-root'),
  );
};

Popup.propTypes = {
  size: PropTypes.string,
  position: PropTypes.string,
  heading: PropTypes.arrayOf(PropTypes.string),
  subHeading: PropTypes.arrayOf(PropTypes.string),
  close: PropTypes.func,
  enableOutsideClick: PropTypes.bool,
};

export default Popup;
